.driver-physical-type-view{
        .label{
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          font-weight: 500;
        }
    
        .description-textbox{
          width: 237px;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          margin-top: 4px;
        }
      }