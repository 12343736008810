.vehicle-view {
 
  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    min-height: 600px;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;

      .driver {
        flex: 1 1;
      }

      .emergency {
        flex: 1 1;
      }
    }
  }

  .generic-panel {
    flex: 1 1;
    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 2rem 0 2rem;

        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;
          width: 63%;

          .ember-text-field {
            height: 25px;
            width: 100%;
            flex: 0 0 80%;
          }

          label {
            flex: 0 0 57%;
            white-space: nowrap;
            margin: 0;
          } .ember-power-select-trigger {
            flex: 0 0 80%;
            height: 25px;
            width: 100%;
          }

          .datetimeflatpickr-wrapper {
            flex: 0 0 80%;
            input {
              width: 100%;
            }
          }

          .ember-text-field-holder {
            flex: 0 0 80%;
          }
        }
      }
    }
  }
.vehicle-panel {
  .input-checkbox {
    position: relative;
    top: 6px;
    margin-right: 0.5rem;
  }
  .input-group-checkbox  {
    height: 25px;
    display: flex;
    margin-bottom: 0.5rem;

    label {
      flex: 0 0 36%;
    }

    .ember-text-field{
      flex: 0 0 64%;
    }
  }
}

.notes-panel {
  .ember-text-area {
    width: 98%;
    height: 160px;
  }
  .text-area-container{
    display: flex;
    height: 160px;
  }
}

 .error{
    input,
    .ember-power-select-trigger {
      border: solid 2px $error-dds !important;
    }
  }

}
