.ember-text-field-holder {
  &.error {
    input {
      border: 2px solid $otp-danger !important; 
    }
  }

  input {
    width: 100%;
    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;
      background: transparent;
  
      &:hover {
        color: #aeaeae;
      }
    }
  }
}