// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Currently this file will hold all Adept-IQ Constants.
// In the future possibly it'd be better to place the constants that only apply to specific components to be with their components
// Adept IQ uses Bootstrap, most of the constants labeled will be attached with *-dds to let devs knows theres a bootstrap constant
// Otherwise dds constants are explicit for dds global styling specific to our app and may possibly differ from bootstrap's global styling
// we can talk about overriding bootstrap variables to use our dds specific variables thus reducing amount of scss variables

// Bootstrap Color System
// $light-dds:    #fff !default;
// $grey: #F0F2F3;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black:    #000 !default;

// --- Adept-IQ Constants ---

// Adept IQ Colors

// --- Primary Color ---
// A primary color is the color displayed most frequently across your app's screens and components.
// Primary Color Palate
$dds-primary: #7cb342; /* rgb(124, 179, 66) */

$dds-100: #dcedc8;
$dds-200: #c5e1a5;
$dds-300: #aed581;
$dds-400: #9ccc65;
$dds-500: #8bc34a;
$dds-600: #7cb342;
$dds-700: #689f38;
$dds-800: #558b2f;
$dds-900: #33691e;

$dds-primary-variants: (
  "100": $dds-100,
  "200": $dds-200,
  "300": $dds-300,
  "400": $dds-400,
  "500": $dds-500,
  "600": $dds-600,
  "700": $dds-700,
  "800": $dds-800,
  "900": $dds-900
);

$primary: $dds-primary;

// --- Secondary Color --- What is our Secondary Color? Is there one?
// What Components typically use a secondary color
// Floating action buttons
// Selection controls, like sliders and switches
// Highlighting selected text
// Progress bars
// Links and headlines

$dds-primary-grey: #303e44;

// Grey Color Palate
// use a grey variant and stay in that palate for all our grey shade needs to complement our grey colors
$dds-grey-100: #cdd4d6;

$dds-grey-200: #acb8bd;
$dds-grey-300: #8a9ca3;
$dds-grey-400: #708790;
$dds-grey-500: #57737e;
$dds-grey-600: #4c646e;
$dds-grey-700: #3e5158;
$dds-grey-800: #303e44;
$dds-grey-900: #20292e;
$dds-grey-variants: (
  "100": $dds-grey-100,
  "200": $dds-grey-200,
  "300": $dds-grey-300,
  "400": $dds-grey-400,
  "500": $dds-grey-500,
  "600": $dds-grey-600,
  "700": $dds-grey-700,
  "800": $dds-grey-800,
  "900": $dds-grey-900
);

$secondary: dds-grey("300");

// --- Alternate Colors ---
$info-dds: #1366a5;
$warning-dds: #e36119;
$danger-color: #dc3545;
$success-dds: $dds-800;
$error-dds: #a51621;
$light-dds: white;
$dark-dds: black;

// default for active state, focus, hover, disable, and outline
$active-primary: $dds-700;
$active-alt: $light-dds;
$focus-primary: $dds-700;
$focus-alt: $light-dds;
$hover-primary: $dds-700;
$hover-alt: $light-dds;
$outline-primary: $dds-700;
$outline-alt: $light-dds;
$disable-text-dds: #aeaeae;
$disable-gradient: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
// default for box-shadow
$box-shadow-color: rgba(0, 0, 0, 0.5);

// OTP colors
$otp-ontime: #9ccc65;
$otp-danger: #d13730;
$otp-warning: #f09240;

// --- Background Colors ---
$background-white: #fff;
$background-dark-dds: $dds-grey-900;
$background-dark-gradient-dds: linear-gradient(#334247, #171f22);
$background-light: #e6e7eb;

$background-lighter: #f5f5f7;
$disable-background-gradient: $disable-gradient; // invision mockup deselect all button uses this
$disable-background: $dds-grey-600; // every other button could use this

// --- Global Font Styling ---
// Typography
// Font, line-height, and color for body text, headings, and more.
$font-family-dds: "AvenirNext";
$font-family-dds-medium: "AvenirNextMedium";
$font-family-dds-demi-bold: "AvenirNextDemiBold";
$font-family-dds-bold: "AvenirNextBold";

// global font styles
$ui-font: $font-family-dds;
$font-color: $dds-primary-grey;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-base-dds: 1rem; // typically 16px
$font-size-xsm-dds: $font-size-base-dds * 0.75 !default; // 12px
$font-size-sm-dds: $font-size-base-dds * 0.875 !default; // 14px
$font-size-lg-dds: $font-size-base-dds * 1.25 !default; // 20px

$font-weight-lighter-dds: lighter;
$font-weight-light-dds: 300;
$font-weight-normal-dds: 400;
$font-weight-bold-dds: 700;
$font-weight-bolder-dds: bolder;

$font-weight-base-dds: $font-weight-normal-dds;

// typically using flex box does not correlate well with line height
// if using flex box to align for vertical alignment use align-content
// if using flex box for horizontal alignment use justify-content
$line-height-base-dds: 1.5;
$line-height-sm-dds: 1.25;
$line-height-lg-dds: 2;

// --- Border ----

$border-width-dds: 1px !default;
$border-color-dds: rgba(38, 50, 56, 0.2) !default;

$border-radius-dds: 0.25rem !default;
$border-radius-sm-dds: 0.2rem !default;
$border-radius-lg-dds: 0.3rem !default;

$rounded-pill-dds: 50rem !default;

// --- Box-Shadow Options ---
// Use box-shadow mixin if these do not work for your component
$box-shadow-dds: 0 0 8px $box-shadow-color !default; // box-shadow on bottom of rectangle
$box-shadow-sm-dds: 0 0.125rem 0.25rem, $box-shadow-color !default;
$box-shadow-lg-dds: 0, 1rem, 3rem, $box-shadow-color !default;

// --- Transition Options ---
// use transition mixin if these options do not work
$transition-base-dds: all 0.2s ease-in-out !default;
$transition-fade-dds: opacity 0.15s linear !default;
$transition-collapse-dds: height 0.35s ease !default;

// --- HTML Global Styling ---

// Header Global Styles <h1...h6>
$h1-font-size-dds: $font-size-base-dds * 2.5 !default; // 40px
$h2-font-size-dds: $font-size-base-dds * 2 !default; // 32px
$h3-font-size-dds: $font-size-base-dds * 1.75 !default; // 28px
$h4-font-size-dds: $font-size-base-dds * 1.5 !default; // 24px
$h5-font-size-dds: $font-size-base-dds * 1.25 !default; // 20px
$h6-font-size-dds: $font-size-base-dds; // 16px

// spacer
$spacer: 1rem;
$headings-margin-bottom-dds: $spacer / 2 !default;
$headings-font-family-dds: null !default;
$headings-font-style-dds: null !default;
$headings-font-weight-dds: 500 !default;
$headings-color-dds: null !default;

// Paragraphs <p>
// Lists <ul> <ol>
// List Items <li>

// Links <a>
$link-primary: $dds-primary;
$link-alt: $light-dds;
$link-focus: $focus-primary;
$link-focus-alt: $focus-alt;
$link-hover: $hover-primary;
$link-hover-alt: $hover-alt;
$link-text-disabled: $disable-text-dds;
$link-bg-disabled: $disable-background-gradient; // typically link background aren't colored

// Buttons + Forms
// <input />
// to differ from Shared variables in bootstrap we are reassigned to `$input-` and `$button-` specific variables.

// input font style
$input-line-height: $line-height-base-dds;

// input text color
$input-color: $font-color;
// input background color
$input-bg-light: $light-dds;

// input border
$input-border-width: 1px;
$input-border-color: $dds-grey-100;

// <input role='button' />
$input-button-font-family: $font-family-dds !default;
$input-button-font-size: $font-size-base-dds !default;

$input-button-focus-width: 0.2rem !default; // 3.2px
$input-button-focus-color-opacity: 0.25 !default;
$input-button-focus-color: rgba(
  $dds-primary,
  $input-button-focus-color-opacity
) !default;
$input-button-focus-box-shadow: box-shadow(
  0,
  0,
  0,
  $input-button-focus-width,
  $input-button-focus-color
) !default;

$input-button-font-size-sm: $font-size-sm-dds !default;
$input-button-font-size-lg: $font-size-lg-dds !default;

$input-button-border-width: $border-width-dds !default;
$input-button-border-color: $border-color-dds !default;

// Buttons
// <button> </button>
// to differ from Bootstrap's buttons, define text, background, and border color.

$button-font-family: $input-button-font-family !default;
$button-font-size: $input-button-font-size !default;

$button-font-size-sm: $input-button-font-size-sm !default;
$button-font-size-lg: $input-button-font-size-lg !default;

$button-border-width: $input-button-border-width !default;

$button-font-weight: $font-weight-normal-dds !default;
$button-box-shadow: inset 0 1px 0 $box-shadow-color !default;
$button-focus-width: $input-button-focus-width !default;
$button-focus-box-shadow: $input-button-focus-box-shadow !default;
$button-disabled-opacity: 0.65 !default;
$button-active-box-shadow: inset 0 3px 5px $box-shadow-color !default;

// link infers as the text inside the button
$button-link-color: $link-primary !default;
$button-link-alt-color: $link-alt !default;
$button-link-hover-color: $link-hover !default;
$button-link-hover-alt-color: $link-alt !default;
$button-link-disabled-color: $disable-text-dds !default;

// button background
$button-bg-primary: $dds-primary;
$button-bg-light: $background-light;
$button-bg-grey: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);

// outline for buttons
$button-outline-primary: $dds-primary;
$button-outline: $dds-grey-200;

// button highlighted state
$button-highlighted-color: $dds-700;
$button-highlighted-alt-color: $light-dds;
$button-highlighted-bg-color: darken($dds-primary, 8%);
$button-highlighted-alt-color: $dds-grey-800;

// button disable state
$button-disabled-background: $disable-background-gradient;
$button-disabled-alt-background: $dds-grey-600;
$button-disabled-color: $dds-primary-grey;

// button focus state

// Allows for customizing button radius independently from global border radius
// we can also use a mixin to define different border radiuses
$button-border-radius: $border-radius-dds !default;
$button-border-radius-sm: $border-radius-sm-dds !default;
$button-border-radius-lg: $border-radius-lg-dds !default;

$button-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// icon colors
$icon-light-color: $dds-grey-300;
$icon-light-highlighted-color: $dds-primary-grey;
$icon-dark-color: white;
$icon-dark-highlighted-color: $dds-primary;

// Forms

// Form validation

// $form-feedback-margin-top:          $form-text-margin-top !default;
// $form-feedback-font-size:           $form-text-font-size !default;
// $form-feedback-font-style:          $form-text-font-style !default;
// $form-feedback-valid-color:         $success !default;
// $form-feedback-invalid-color:       $danger !default;

// $form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
// $form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
// $form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
// $form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

// scss-docs-start form-validation-states
// $form-validation-states: (
//   "valid": (
//     "color": $form-feedback-valid-color,
//     "icon": $form-feedback-icon-valid
//   ),
//   "invalid": (
//     "color": $form-feedback-invalid-color,
//     "icon": $form-feedback-icon-invalid
//   )
// ) !default;
// scss-docs-end form-validation-states

// Tables <table>

// Table headers <th>

// Table rows <tr>

// table cells <td>

// Z-index master list bootstrap elements
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// // scss-docs-start zindex-stack
// $zindex-dropdown:                   1000 !default;
// $zindex-sticky:                     1020 !default;
// $zindex-fixed:                      1030 !default;
// $zindex-modal-backdrop:             1040 !default;
// $zindex-modal:                      1050 !default;
// $zindex-popover:                    1060 !default;
// $zindex-tooltip:                    1070 !default;
// // scss-docs-end zindex-stack

// Z-index master list adept-iq
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// z-index
$z-index: (
  dropdown: 9002, // dropdown are universal it'd be better for them to be higher on zindex plane
  action-modal: 9001,
  modal: 9000,
  overlay: 8000,
  timeline-picker: 6000,
  header: 6000,
  footer: 5000
);

// --- Component Global Constants ---

// --- top menu (navigation) & workspace navigation ---
// top menu layout
$nav-height: 50px; // might need to be shared with other components
// secondary menu (workspace navigation) layout
$workspace-nav-height: 40px; // might need to be shared with other components
// total height for both top (navigation) menu and secondary (workspace) menu
$total-nav-height: calc(#{$nav-height} + #{$workspace-nav-height});

// --- ember light table component ---

// table colors
$table-body-text: $dds-grey-900;
$table-background-color: #fff;

// table header
$table-header-left-border-width: 3px;
$table-header-background-color: white;
$table-column-header-background-color: rgb(245, 245, 247);

// table row
$table-row-alt-background-color: rgba(230, 231, 235, 0.4);
$table-row-selected-color: #7cb34245;
$table-row-left-border-width: 5px;

// table cell
$table-cell-selected-color: #5d863145;

// --- widget configuration ---

// widget font typography
$widget-title-font-size: 13px;
$widget-title-mobile-font-size: 12px;
$widget-body-font-size: 12px;
$widget-body-mobile-font-size: 14px;
$widget-input-font-size: 12px;
$widget-input-mobile-font-size: 15px;
$widget-button-font-size: 13px;

// widget colors
$widget-shadow-color: #b3b3b3;
$widget-outline-color: $dds-primary-grey;
$widget-drag-handle-color: #b7b7b7;
$widget-dropdown-shadow: #7d7a7a;

// widget button colors
$widget-button-color: $dds-grey-300;
$widget-button-focused-color: $dds-primary;

// widget layout
$widget-border-width: 0px;
$widget-header-height: 34px;
$widget-header-mobile-height: 46px;
$widget-header-padding: 6px;
$widget-header-mobile-padding: 10px;
$column-title-margin: 8px;

// --- trip ---
$btn-text-color: #363b42;
$btn-border-color: #b4b4b4;
$re-schedule-btn-bg-grey: linear-gradient(180deg, #f9f9f9, #d1d1d1);

$sidedrawer-table-background-color: #F5F5F7;
$sidedrawer-table-border-color: #CCCED0;