.cell-text-content-extension {
  color: #657175;
  font-weight: 300;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  max-height: 300px;
  overflow-y: auto;

  label.mainItem {
    font-weight: 600;
    margin-top: 1px;
    margin-bottom: 3px;
    font-size: $widget-title-font-size;
  }

  label.valueItem {
    font-size: $widget-body-font-size;
    margin-bottom: 3px;
  }

  .header {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
