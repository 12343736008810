.tab-group {
  display: flex;
  height: 40px;

  &::after {
    border-width: 0;
  }
  &::before {
    border-width: 0;
  }

  .tab-button {
    @include tab-button(black, $light-dds, $dds-primary, 0);
    font-size: 13px;
    font-weight: 700;
    height: inherit;
  }
}