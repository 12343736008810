.reconcile-search-mode-pop-up {
  .width-100 {
    width: 100px !important;
  }

  .width-130 {
    width: 130px !important;
  }
  .width-150 {
    width: 150px !important;
  }
  .width-160 {
    width: 160px !important;
  }

  .width-180 {
    width: 180px;
  }
  .width-200 {
    width: 200px;
  }
  .width-220 {
    width: 220px;
  }
  .width-260 {
    width: 260px;
  }
  h2.table-header-title {
    font-size: 14px !important;
    height: 30px !important;
    line-height: 30px !important;
    color: #263238 !important;
    background-color: #fff !important;
  }

  .font-12 {
    font-size: 12px;
  }
  .vehicle-dropdown{
    padding-right: 15px;
    border-right: 1px solid #ccc;
    box-sizing: initial;
    .ember-power-select-trigger {
    font-size: 12px;
    display: block;
    width: 100%;
    padding: 3px 7px 0 7px;
    line-height: 1.5;
    color: #303e44;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #cdd4d6;
    border-radius: 3px;
    outline: none;
    }
  }
  .longTable {
    padding-left: 420px;
    text-align: left;
  }
}
