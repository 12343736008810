#change-password-confirmation {
  margin: 0 auto;
  width: 50%;
  max-width: 480px;
  font-family: $ui-font;
  font-weight: 500;

  h1 {
    margin-top: 100px;
    font-weight: 700;
  }

  .content {
    padding-top: 1rem;
    border-top: 1px solid #b5b5b5;
    text-align: right;

    label {
      width: 100%;
      text-align: left;
      margin-bottom: 120px;
    }

    .btn {
      line-height: 1.1;
      font-size: 15px;
      font-weight: 400;
      padding: 8px 15px;
      margin: 0px 5px;
      width: 85px;
      color: #7cb342;
      border: 1px solid #7cb342;

      &:hover {
        background-color: $button-bg-primary;
        color: $button-link-alt-color;
        border: 1px solid $button-bg-primary;
      }
    }
  }
}