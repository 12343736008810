$generic-panel-width: calc(50% - 0.25rem);

.providers {
  label {
    margin: 0;
  }
  .container {
    padding: 0.5rem;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
  }

  .left-form-panel-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 $generic-panel-width;
  }

  .error {
    input,
    .ember-power-select-trigger {
      border: solid 2px $error-dds !important;
    }
  }

  .generic-panel {
    position: relative;
    flex: 0 0 $generic-panel-width;

    &:first-child {
      margin-right: 0.5rem;
    }

    &.trip-count {
      width: 100%;
      margin-top: 0.5rem;

      .trip-count-form-container {
        display: flex;
        flex-direction: row;
        height: 30px;
        .section {
          margin: 10px;

          &:first-child {
            min-width: 94px;
          }

          label {
            font-weight: 600;
            font-size: $font-size-xsm-dds;
          }
        }

        .datetimeflatpickr-wrapper i {
          right: 16%;
        }
      }

      .spacer-0 {
        min-width: 1rem; // 16px
      }

      .table-header {
        font-size: 12px;
        font-weight: 600;
        vertical-align: bottom;
        width: 100%;
      }

      .input-cell {
        width: 110px;
      }

      .days-of-service-table {
        white-space: nowrap;

        input {
          border-radius: 2px;
          font-size: $font-size-xsm-dds;
          font-weight: 600;
          line-height: 1rem;
          height: 25px;
          padding: 3px 7px 0;
          display: inline-block;
        }

        .input-cell {
          width: 110px;
        }
      }
    }

    &.cost-info {
      width: 100%;
    }

    .ember-power-select-trigger {
      max-width: 50%;
      font-weight: 100 !important;
      line-height: 20px;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding-top: 0;

        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          height: 30px;

          .ember-text-field {
            flex: 0 0 50%;
            height: 25px;
            margin-right: 4rem;
          }
        }

        .input-group.zipcode-options {
          align-items: normal;

          ul.ember-power-select-multiple-options {
            height: 70px;
            overflow-y: auto;
          }
        }


        .input-group.input-box {
          div {
            flex: 0 0 50%;
          }
        }

        .contact-information-panel,
        .main-information-panel,
        .trip-count-panel,
        .service-zone-panel {
          align-items: baseline;
          flex-wrap: nowrap;
          flex-direction: column;

          label {
            flex: 0 0 25%;
            white-space: nowrap;
          }
          .new-zone-code {
            .ember-power-select-trigger--active {
              border: solid 2px $dds-800;
            }
          }

          .ember-power-select-trigger {
            ul {
              li {
                display: none;
              }
            }
            .ember-power-select-trigger-multiple-input {
              min-width: 100%;
              border: none;
              padding: 0;
            }
          }
        }

        .cost-count-panel {
          .ember-power-select-trigger {
            max-width: calc(100% - 30px);
          }
          .ember-text-field {
            flex: 0 0 calc(100% - 30px);
          }
          .cost-header {
            padding-left: 0.5rem;
          }
          .cost-header,
          .input-group-container {
            display: flex;
            flex-direction: row;
            height: 25px;
            padding-left: 1rem;
            .input-group,
            label {
              flex: 0 0 33%;
            }
            &.new-cost .input-group {
              .ember-power-select-trigger--active {
                border: solid 2px $dds-800;
              }
              .ember-text-field:focus {
                border: solid 2px $dds-800;
              }
            }
          }
        }
      }
    }
  }
  .panel-header {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .panel-header-buttons {
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1.1;
      cursor: default;
      .btn {
        padding: 0 5px;
        font-size: 12px;
        margin-left: 7px;
        i {
          margin-right: 0;
        }
      }
    }
  }
  
  .trip-count-datePicker {
    height: fit-content;
  }
  .trip-count-dateInput {
    border: none;
  }
  .trip-count-dateInput input[type="text"]{
    border: none!important;
  }
  .trip-count-dateInput a{
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
  }
}
.ember-modal-dialog .system-config-wrapper input {
  &.input-add-zone:focus {
    border: solid 2px $dds-800;
  }
  &.error {
    border: solid 2px $error-dds;
  }
}
