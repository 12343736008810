.reconcile-dates {
  .btn-position {
    position: absolute;
    top: 5px;
    right: 20px;
  }

  .g-side-drawer-panel {
    position: relative;
  }

  .g-side-drawer-body {
    display: block;
    margin-top: 8px;
    background: white;
  }
  .provider-dropdown{
    width: 100px !important;
  }
  
  .ember-light-table {
    margin: 0px 15px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    width: 180px;
    .lt-head th {
      background-color: rgba(230, 231, 235, 0.4);
    }
    .lt-cell{
      height: 20px;
      padding: 3px;
      font-size: 12px;
      text-align: center;
    }
    .lt-head th,
    .lt-head td {
      padding-left: 8px !important;
      padding-right: 8px !important;
      font-weight: bold;
      font-size: 13px;
    }
    .lt-head th:last-child .lt-column-resizer{
      display: none;
    }

    .lt-head tr th:last-child {
      text-align: center !important;
    }
    .lt-head-wrap {
      padding-right: 0px !important;
      border-radius: 5px 5px 0 0;
    }

    .lt-body-wrap {
      border-radius: 0 0 5px 5px;
    }

    .lt-head {
      .table-check-box-column {
        padding-left: 8px;
      }
    }

    .lt-column {
      &:focus-within {
        background-color: $table-cell-selected-color;
      }
    }

    .otp-danger .highlightable {
      color: $otp-danger;
    }

    .otp-warning .highlightable {
      color: $otp-warning;
    }
    .lt-row:nth-child(odd) {
      background-color: rgba(230, 231, 235, 0.4);
    }
    .lt-row:nth-child(even) {
      background-color: #fff;
    }
  }
}
