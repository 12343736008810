.driver-accident-view {
  .driver-accident-table {
    height: 200px;
  }

  label {
    margin: 0;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    min-height: 300px;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    button {
      @include button();
      height: 23px;
      line-height: 1rem;
      i {
        font-size: $font-size-xsm-dds;
      }
      &:hover {
        i {
          color: $link-primary;
        }
      }
      &:disabled {
        i {
          color: inherit;
        }
      }
    }
  }

  .generic-panel {

    .row {
      margin-bottom: 0.5rem;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 4rem 0 4rem;

        .ember-power-select-trigger {
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }

        .datetimeflatpickr-wrapper {
          &.error {
            border: 2px solid $otp-danger !important;
          }
        }
      }
    }
  }
}
