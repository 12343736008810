.vehicle-body-width-view{
   
  .form-panel-wrapper{
    margin-top: 10px;
  }
  .generic-panel .panel-body {
    font-size: 12px;
    font-weight: 500;

    .input-group {
      flex-wrap: nowrap;
      width: 80%;
      margin-left: 10%;
      label {
        white-space: nowrap;
        margin-bottom: 1rem;
        margin-right: 10px;
      }
      .ember-text-field {
        height: 25px;
        width: 100%;
      }
      div {
        flex: 0 0 45%;
      }
    }
    .error{
      input{
        border: solid 2px $error-dds !important;
      }
    }
  }
}