.new-booking-form-side-drawer {
  cursor: pointer;
  height: inherit;
  overflow-y: auto;
  .new-booking-form-layout {
    .g-side-drawer-body {
      margin-top: 8px;
      background-color: white;
      .g-side-drawer-panel {
        .g-side-drawer-panel-body {
          position: relative;
          .btn-add-travel-need {
            position: absolute;
            top: -28px;
            right: 10px;
            color: gray;
            border: none;
            background: white;
            border-radius: 3px;
            border: 1px solid #CED4DA;
            height: 24px;
          }
          .view-impact-table-wrapper {
            width: 563px;
            overflow: auto;
            .ember-light-table {
              width: 850px;
            }
            .yellow-table-row {
              background-color: #F0ED92 !important;
            }
          }
          .g-side-drawer-panel-selector {
            &.bulk-trip {
              float: left;
              width: 43%;
            }
            h5 {
              width: 66px;
              margin: 0px 14px 0 15px;
            }
            .dropdown {
              .ember-power-select-trigger {
                width: 155px;
              }
            }
          }
          input#override {
            margin-left: 10px;
          }
          label {
            margin: 5px 0px 7px 9px;
            font-size: 14px;
            font-weight: bold;
          }
          input[type="checkbox"] {
            vertical-align: bottom;
            position: relative;
            top: -4px;
            overflow: hidden;
            margin:auto;
          }
          .amb-button {
            background-color: transparent;
          }
          .flex-seat-label {
            color: gray;
            font-size: smaller;
          }
          .g-side-drawer-panel-form {
            margin-right: 1em;
            border-collapse: collapse;
          }
        }
      }
    }
  }
  .trip-details-form {
    //  background-color: red;
    .address-search-component {
      position: relative !important;
      .address-search-dropdown {
        top: 22px;
        left: 0px;
        width: calc(100% - 0px);
      }
    }
    form {
      select::-ms-expand {
        display: none !important;
      }
      select {
        -moz-appearance: none;
        -webkit-appearance: none;
      }
    }
    .td-display {
      display: flex;
    }
    .trip-locationButton {
      border: none;
      background: white;
      border-radius: 3px;
      border: 1px solid #CED4DA;
    }
    .text-align-center {
      text-align: center;
    }
    .btn-font-size {
      font-size: 13px !important;
    }
    .remove-col-padding {
      padding: 0px !important;
    }
  }
  .subscription-form {
  .icon-parent-div {
    position: relative;
  }
  .iconspan {
    position: absolute;
    top: 0em;
    padding-top: 3px;
    right: 1em;
  }
  .btn-space
  {
    margin-right: 2px;
    padding: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .mtop
  {
    margin-top: 5px;
  }
  .mleft
  {
    margin-left:1.5rem
  }
  .cal-icon
  {
    position: relative;
    bottom: 3px;
    right: 2px;
  }
}
  .leg-form {
    .label-margin {
      margin-top: 0px !important;
    }
    .label-float {
      text-align: right;
      padding-right: 0.5em;
    }
    .remove-search-map-gaps {
      padding: 0px !important
    }

    .icon-parent-div {
      position: relative;
    }
    .iconspan {
      position: absolute;
      top: 0em;
      padding-top: 3px;
      right: 0.6em;
    }

  }
  .passanger-list-form {
    .border {
      width: calc(150% - 54px);
      overflow: auto;
    }
    .text-padding {
      padding-right: 30px;
    }
    .field-label-right {
      text-align: right;
      padding-right: 15px;
    }
    .field-label-left {
      text-align: left;
      padding-right: 15px;
    }
    input {
      font-family: sans-serif;
    }
    .booking-model-label {
      font-size: 12px;
    }

  }
  .travel-needs-form {
    .trashButton {
      border: none;
      background: white;
      border-radius: 3px;
      border: 1px solid #CED4DA;
      margin: 0 5px 0 5px;
      height: 24px;
    }

    .vertical-text-bottom {
      vertical-align: text-bottom;
    }

  }

}

.bulk-transfer {
  hr {
    width: 94%;
    &:last-child {
      display: none;
    }
  }
}

.booking-map-container {
  margin: 20px;
  padding: 0px;
  left: 400px!important;
  .booking-map-header {
    padding: 5px 20px;
  }
  #booking-map {
    width: 100%;
    height: 76vh;
  }

  .my-cust-sec-window{
    .secondary-window {
      .wigt-tbl{
        background-color: white;
        border-radius: 3px;
        line-height: 5px;
      }
      padding-bottom: 5px;
      .secondary-window-modal {
        font-weight: 500;
        min-width: 37vw;
        left: 30%;
        .secondary-window-body {
          padding-bottom: 5px;
          overflow-y: auto;
        }
        .secondary-window-header{
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
          display: block;
          font-size:20px;
          div{
            display: none
          }
          button{
            float: right;
          }
        }
        .dashboard-save-as-controls{
          text-align: right;
          width:91%;
        }
        .btn-pri {
          justify-self: flex-end;
          border-radius: 5px;
          border: 1px solid white;
          display: inline-block;
          cursor: pointer;
          color: white;
          font-size: 14px;
          font-weight: 400;
          height: 26px;
          width: 125px;
          padding: 0px 38px;
          text-decoration: none;
          background-image: linear-gradient(#334247, #171f22);
        }
      }
    }
  }
  .sec-w-m {
    .secondary-window {
       height: 50em;
      .secondary-window-modal {
        top: 30%;
        left: 20%;
        min-width: 30vw;
        height: 25%;
      }
    }
  }
  .centered-scrolling-overlay {
    position: relative;
    min-height: 100vh;
    padding: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  /* basic modal style (an example, this is not necessary for the centering) */
  .centered-scrolling-container {
    position: relative;
    background-color: white;
    min-width: 60em;
    max-width: 650px;
    min-height: 30em;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-left: 200px;
    box-shadow: 0px 4px 25px 4px rgba(0, 0, 0, 0.30);
  }
  .sp-header {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sp-heading {
    padding: 3px 9px;
    font-weight: 500;
    font-size: 18px;
    background-color: #F6F6F6;
    border-radius: 7px 7px 0 0;
    .x-button {
      float: right;
      color: rgb(59, 57, 57);
    }
    .question-image {
      padding: 2px;
      float: right;
      color: #bdc5d4a8;
    }
  }
  .btn-pri {
    justify-self: flex-end;
    border-radius: 5px;
    border: 1px solid white;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    width: 125px;
    padding: 0px 38px;
    text-decoration: none;
    background-image: linear-gradient(#334247, #171f22);
  }
  .addressvalidationButton {
    margin-left: 300px !important;
    width: 100px !important;

  }

}

.ember-modal-overlay.translucent.booking-detail-overlay {
  z-index: zindex(action-modal);
}

.booking-detail-container {
  overflow-y: auto;
  height: 460px;
  width: 75%;
}
.column-widget .column-widget-body
.trip-alternative-body {
  .ember-light-table {
    width:fit-content!important; ;
  }
  position: relative;
  top: 20px;
}

.trip-alternative-container {
  overflow-y: hidden !important;
}

.bd-body {
  .g-side-drawer-panel {
    background-color: #f5f5f7;
    border: 1px solid #ccced0;
    border-radius: 4px;
    margin: 0px;
  }
}

.bd-body > div:first-child {
  padding: 12px;
}

.book-trip {
  margin: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
  .btn-secondary {
    color: #5b5b5b;
    background-color: #dfdfdf;
    border-color: #bab6b6;
    min-width: 90px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 2px;
    margin-right: 20px;
  }
}

.tr-header {
  font-size: 12px;
  font-weight: bold;
}

.booking-model-label {
  font-size: 12px;
}

.ember-modal-overlay.translucent {
  overflow-y: auto !important;
}

.bd-table-title {
  left: 0px;
  position: absolute;
}

.bd-table-controls {
  position: absolute;
  right: 0px;
}

.booking-details-wrapper {
  .sp-header {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sp-heading {
    padding: 3px 9px;
    font-weight: 500;
    font-size: 18px;
    background-color: #F6F6F6;
    border-radius: 7px 7px 0 0;
    .x-button {
      float: right;
      color: rgb(59, 57, 57);
    }
    .question-image {
      padding: 2px;
      float: right;
      color: #bdc5d4a8;
    }

  }
  .ember-light-table {
    table {
      border: 2px $input-border-color;
      border-radius: 3px;
      border-spacing: 0;
      border-collapse: separate;
      min-width: 200px;
      width: 100%;
      vertical-align: middle;
    }
  }
}


.disable-pick-address {
  pointer-events: none;
  opacity: 0.4;
}

.passenger-name-list {
  background: inherit;
  border: 1px solid white;
  padding-left: 1.8em;
  height: 35px;
  font-size: 12px;
  font-family: "AvenirNext";
  line-height: 2.7em;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  min-height: 32px
}

.global-spinner{
  z-index: zindex(dropdown);
}
.daysCheckbox {
  position: relative;
}

.daysCheckbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  font-size: 16px;
  padding: 2px 14px 6px 3px;
}

.daysCheckbox label:after {
  border: 2px solid #fff;
  height: 6px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 12px;
}

.daysCheckbox input[type="checkbox"] {
  visibility: hidden;
}

.daysCheckbox input[type="checkbox"]:checked + label {
  background-color: #41ab11;
  border-color: #41ab11;
  COLOR: WHITE;
}

.daysCheckbox input[type="checkbox"]:checked + label:after {
  display: none;
}
.holidaysField{
  width:85px !important;
}
.subscription-label{
  font-weight: 500 !important;
}

.red-text {
  color:red !important;
}
