.rescue-route-side-drawer{
  .g-side-drawer-body{
    display: block;
    margin-top: 8px;
    background: white;
  }

  .ember-light-table {

    .lt-head {
      .table-check-box-column {
        // padding-left: 8px;
      }
    }
    
    .lt-column {
      &:focus-within {
        background-color: $table-cell-selected-color;
      }
    }

    .otp-danger .highlightable {
      color: $otp-danger;
    }

    .otp-warning .highlightable {
      color: $otp-warning;
    }

  }

}