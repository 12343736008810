.form-components-text-area {
  &.error {
    textarea {
      border: 2px solid $otp-danger !important;
    }
  }
}

.text-area-container {
  display: flex;
  height: 100px;
  textarea {
    flex: 1 1 auto;
    resize: none;

    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;
      background: transparent;
  
      &:hover {
        color: #aeaeae;
      }
    }
  }
}

.hint {
  display: flex;
  justify-content: flex-end;
  color: $icon-light-color;
}