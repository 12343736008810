// side-drawer table colors
$system-config-table-background-color: $background-lighter;
$system-config-table-border-color: #ccced0;
$system-config-header-color: rgba(199, 199, 199, 0.5);

.system-config-side-drawer {
  .system-config-wrapper {
    display: flex;
    height: inherit;
    .left-panel {
      flex: 1 0 auto;
      height: inherit;
      overflow: auto;
      background-color: $background-lighter;
      .g-side-drawer-header {
        padding: 10px 0 10px 0;
        background-color: $light-dds;
        position: relative;
        @include box-shadow(0, 2px, 4px, $system-config-header-color);
      }
      .system-config-search-panel {
        background-color: $system-config-table-background-color;
        display: flex;

        box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.4);

        z-index: 5;

        .system-config-search-input {
          margin: 0px 10px 10px;
        }
      }
      .component-link {
        font-size: 12px;
        font-weight: 700;
        text-indent: 20px;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        background: $background-lighter;
        border-bottom: 1px solid $system-config-table-border-color;
        &.active{
          background-color: $table-row-selected-color;
        }
      }
    }

    .right-panel {
      height: inherit;
      overflow-y: auto;
      background-color: $side-drawer-modal-background-color;
      color: $side-drawer-modal-font-color;
      border-left: 1px solid $system-config-table-border-color;
      min-width: 400px;
      width: 100%;
      flex: 2 1 auto;
    }
  }
}
