.reconcile-widget {
  $column-title-margin: 6px;
  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  font-family: $ui-font;

  .reconcile-widget-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;
    height: 34px;

    .reconcile-widget-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      flex-shrink: 0;
      // height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .reconcile-widget-button {
        flex: 0 0 30px;
        padding: 0 5px 0 5px;
        margin-right: 8px;
        font-size: 18px;
        display: inline-block;
        background-color: inherit;
        border: none;
        opacity: 0.5;
        cursor: pointer;
        outline: none;
        &.disabled {
          color: $widget-button-color;
          pointer-events: none;
        }
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          transition: color 0.2s;
          opacity: 1;
        }

        &.disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            color: $widget-button-color;
            transition: none;
            opacity: 0.2;
          }
        }

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;
        }
      }
    }
  }
  .form-check{
      padding-left: 0;
      padding-right: 1.25rem;
  }
  .form-widget input.flatpickr-input:read-only{
    background-color: #fff !important;
  }
  .form-widget input.flatpickr-input:read-only:disabled{
    background-color: #f5f5f7 !important;
  }
  .form-widget-subheader {
    .form-widget-readonly-section {
      .readonly-label {
        display: block;
        padding-right: 10px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 600;
        span {
          vertical-align: middle;
        }
      }
    }
  }
  .route-info {
    background-color: #f5f5f7 !important;
  }
  .column-widget {
    .column-widget-header {
      .column-widget-controls .column-widget-button.disabled-fields  {
        pointer-events: none;
      }
      .ember-power-select-trigger {
        font-size: 12px;
        display: block;
        width: 100%;
        padding: 0 7px 0 7px;
        line-height: 1.5;
        color: #303e44;
        background-color: white;
        background-clip: padding-box;
        border: 1px solid #cdd4d6;
        border-radius: 3px;
        outline: none;
        .ember-power-select-placeholder {
          margin-left: 0;
        }
      }
      .column-widget-title {
        h5 {
          font-weight: 600;
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  .route-action {
    width: 160px;
  }
  .valign {
    vertical-align: top !important;
    padding-top: 5px !important;
  }
  .route-type {
    > div {
      width: 50%;
    }
  }
  .noninput {
    font-weight: 500;
    padding-left: 25px;
  }
  .h-95{
    height: 437px;
  }
  .section-table {
    border: 1px solid $form-widget-table-border-color;
    border-radius: 3px;
    border-spacing: 0;
    border-collapse: separate;
    min-width: 360px;
    width: 100%;
    vertical-align: middle;
    background-color: $form-widget-table-background-color;
    margin: 10px 0 0 0 !important;
    .cursor-none {
      cursor: initial;
    }
    .cell-header {
      h4 {
        padding: 2px 5px 2px 12px;
        margin-top: 0;
        font-size: 14px;
        margin-bottom: 14px;
        cursor: pointer;
        i {
          margin-right: 3px;
          transform: rotate(-90deg);
          &::before {
            top: -3px;
            position: relative;
            font-size: 12px;
          }
        }        
      }
    }

    tr {
      th,
      td {
        padding-bottom: 4px;       
      }
      .edit-cell .data-test-breakdownTime-input{
        background-color: #fff !important;
     } 
     
    }
    textarea{
      resize:none;
    }
    &.h-95{
      i{
        transform: rotate(0deg) !important;
      }
    }
  }
  .edit-cell {
    width: 100%;
    min-width: 100px;
    font-size: $widget-body-font-size;
    padding: 1px 0 1px 10px;

    .form-components-datetimeflatpickr {
      width: 100%;
      height: 22px;

      .flatpickr-input {
        height: 22px;
        padding-left: 7px;
      }
    }
  }
  .cell-label {
    font-size: 14px;
    line-height: 1em;
    height: 100%;
    padding: 1px 0 1px 28px;
    text-align: left;
    vertical-align: middle;
    min-width: 170px;
    font-weight: 600;
  }

  .miles-section{
    border: 1px solid rgba(38, 50, 56, 0.2);
    border-radius: 2px;
    padding: 6px;
    width: 385px;
    margin: 20px auto 10px;
    .miles-box{
      display: flex;
      align-items: center;
      label{
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        margin-bottom: 0;
      }
      input{
        border: none;
        background-color: transparent;
      }
    }
  }
  .location-start-end{
    position: absolute;
    top: 19px;
    left: 186px;
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% - -67px);
      border: 1px solid #CCCED0;
      left: 0;
    }
  }

  // css used for verify route pop-up, will separate///

  .reconcile-table-header-column {
    display: flex;
    .column-label {
      flex-basis: 30px;
      flex-grow: 1;
      flex-shrink: 1;
      line-height: 23px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .column-controls {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 12px;
      padding-top: 4px;
      white-space: nowrap;
    }
  }
  .reconcile-widget-title {
    font-weight: 600;
    font-size: 16px;
    margin: 0 0 0 8px;
  }
  ///@at-root
  .reconcile-widget-table-container {
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 0;
    display: flex;

    .reconcile-widget-table-box {
      &:first-child {
        width: 70%;
      }
      &:last-child {
        width: 30%;
      }
      .reconcile-widget-table {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 300px;
        width: calc(100% - #{$widget-border-width});
        overflow: hidden;
        font-size: $widget-body-font-size;
        flex-direction: row;
        display: grid;
          height: calc(100% - 40px);
        overflow: auto;
           .grid-table-left {
          height: 100%;
       
        }
    
        .reconcile-widget-table-header {
          flex-basis: 34px;
          flex-grow: 0;
          flex-shrink: 0;
          padding: 6px;
          overflow-y: hidden;
          display: flex;
          justify-content: space-between;
          background-color: white;
          position: relative;
          .reconcile-widget-table-title {
            flex-grow: 1;
            flex-shrink: 0;
            line-height: 22px;
            margin: 0 0 0 6px;
            padding-left: 7px;
            font-weight: 600;
            font-size: 13px;
          }
        }
      
        .reconcile-widget-table-body {
          width: calc(100% - 0px);
          overflow-x: auto;
          overflow-y: hidden;
          flex-direction: row;
          display: flex;
          height: 100%;
        }
      
        .ember-light-table {
          overflow: visible;
          font-feature-settings: "tnum";
          width: fit-content;
          overflow-x: hidden !important;
      
          input[type="checkbox"] {
            font-size: 20px;
          }
      
          .lt-head-wrap {
            background-color: $table-column-header-background-color;
            /* box-shadow: 0px 0px 5px 0px $grid-color; */
            box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
            z-index: 1;
            overflow: visible;
            position: sticky;
            top: 0;
          }
      
          .lt-head {
            width: 100%;
            border-left: solid $table-header-left-border-width rgba(255, 255, 255, 0);
      
            th {
              padding: 2px 2px 2px 2px;
              th {
                padding: 4px;
              }
              font-weight: 700;
              opacity: 0.8;
      
              &:first-child {
                padding-left: 7px;
                input {
                  float: left;
                }
              }
            }
            .priorityId {
              display: none;
            }
            // draw line for resize handle
            .lt-column-resizer {
              background: linear-gradient(
                to right,
                transparent 0%,
                transparent calc(50% - 1px),
                #ccc calc(50% - 1px),
                #ccc calc(50% + 0px),
                transparent calc(50% + 0px),
                transparent 100%
              ) !important;
              height: 70%;
              margin-top: 4px;
            }
          }
      
          .lt-column {
            &:focus-within {
              background-color: $table-cell-selected-color;
            }
          }
      
          .otp-danger .highlightable {
            color: $otp-danger;
          }
      
          .otp-warning .highlightable {
            color: $otp-warning;
          }
      
          .lt-row {
            border-left: solid $table-row-left-border-width rgba(255, 255, 255, 0);
      
            &:nth-child(2n-1) {
              background-color: $table-row-alt-background-color;
            }
      
            &.is-selected {
              background-color: $table-row-selected-color !important;
            }
      
            &.is-locked {
              background-color: lightgrey !important;
              color: grey;
            }
      
            &.lt-is-loading {
              text-align: center;
              background-color: transparent;
            }
      
            &.lt-no-data {
              background-color: transparent;
            }
      
            // Status Styles
            // &.late { background: $table-row-late-color; }
            // &.pending { background: $table-row-pending-color; }
            // &.ahead { background: $table-row-ahead-color; }
      
            &.drag-target-above {
              border-top: 15px solid #ccf;
            }
      
            &.drag-target-below {
              border-bottom: 15px solid #ccf;
            }
      
            &.drag-target {
              background-color: #ccf;
            }
      
            &.otp-warning {
              border-color: $otp-warning;
            }
      
            &.otp-danger {
              border-color: $otp-danger;
            }
          }

          .highlightable{
            color:#226e37;
          }
      
          .lt-cell {
            padding: 3px 5px 2px 2px; // same here: we just want the top cell to have padding
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 2px;
            &.priorityId {
              display: none;
            }
      
            &:focus-within {
              background-color: $table-cell-selected-color;
            }
      
            // .lt-cell {
            //   padding: 0 0 0 0;
            // }

            div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
      
          .lt-body-wrap {
            margin-top: 3px;
          }
      
          .lt-body {
            color: $table-body-text;
            font-weight: 500;
            margin-top: 3px;
          }
      
          .is-sorted {
            .lt-sort-icon {
              visibility: visible;
            }
          }
      
          .lt-sort-icon {
            float: none;
            margin-left: auto;
            margin-right: 0;
            padding-left: 2px;
            // visibility: hidden;
            line-height: 20px;
          }
        }
      
        .cell-text-extension {
          background-color: transparent;
          border: 0;
          padding: 0;
          color: #657175;
          font-weight: 300;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .table-seprater {
      border: 2px solid #dfdfdf;
    }
  }
  .editable-cell {
    input {
      background-color: transparent;
      border-radius: 2px;
      width: 100%;
      padding: 1px;
       border: 1px solid transparent;
       border-color: #717171;
    }
  }
  .places-button{
    margin-left: 8px;
    padding: 0 7px;
    height: 23px;
    border-color: #cdd4d6 !important;
    background-color: #fff !important;
    color: #303e44 !important;
  }
  .reconcile-widget-table-title {
    font-size: 16px;
    padding: 8px 10px;
    margin: 0;
}
}


