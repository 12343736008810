$filter-setting-button-color: dds-greys("900");
// side-drawer table colors
$filter-setting-table-background-color: $background-lighter;
$filter-setting-table-border-color: #CCCED0;


.filter-drawer {
  right: calc(100% - 400px);
}
.playback-filter-settings-side-drawer {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  overflow-y: auto;

  color: $font-color;
  font-family: $ui-font;

  margin: 0;

  .side-drawer-subheader {
    background-color: white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
    z-index: 5;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: $ui-font;
    padding: 5px 12px;
    .reorder-column {
      flex: 0 0 5%;
      text-shadow: 0px 1px 1px #4d4d4d;
      color: $filter-setting-button-color;
      z-index: 2;
    }

    .visibility-column {
      flex: 0 0 6%;
      cursor: pointer;
    }

    .header-label-column {
      flex: 0 0 33%;
      z-index: 2;

    }

    .filter-type-column{
      flex: 0 0 30%;
      z-index: 2;

    }
  
    .header-button-outline {
      @include button();
    }

  }

  .side-drawer-table-wrapper {
    overflow-y: auto;

      // table-styling
      .filter-settings-table {
        padding: 8px 10px;
        width: 100%;

        font-weight: 500;

        background-color: $filter-setting-table-background-color;
        border: none;

        .filter-setting-row {
          display: flex;
          flex-direction: row;
          z-index: 2;
          align-items: center;
          padding: 2px 0px 2px 0px;
          .setting-icon{
            display: flex;
            display: flex;
            align-self: center;        
          }
          .display-checkbox{
            flex: 0 0 10%;
            display: flex;
            align-self: center;
          }
          .setting-item {
            flex: 0 0 33%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: $widget-input-font-size;
            font-weight: 500;
            z-index: 2;
          }
          .setting-item-type {
            flex: 0 0 20%;
            margin: 0px 5px 0 5px;
          }
          .setting-item-value {}
        }

        input {
          font-weight: 500;
        }
      }
  }

  .ember-power-select-status-icon {
    color: #000105;
  }


  // ember-sortable styling

  .handle {
    cursor: move;
    width: 10px;
    height: 15px;
    img {
      width: 10px;
      height: 15px;
    }
  }

  .sortable-item {
    transition: all .125s;
    position: relative;
    z-index: 2;

    &.is-dragging {
      transition-duration: 0s;
      background: $filter-setting-table-background-color;
      z-index: 20 !important;
    }

    &.is-dropping {
      background: $filter-setting-table-background-color;
      z-index: 20;
    }
  }
}
