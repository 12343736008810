.form-component-input-mask-layout {
  &.error {
    input {
      border: 2px solid $otp-danger !important;
    }
  }

  input {
    &:disabled {
      cursor: not-allowed;
      color: #aeaeae;
      background: transparent;
  
      &:hover {
        color: #aeaeae;
      }
    }
  }
}
