.driver-review {
  .generic-panel {
    &.provider-information-panel {
      height: 100%;
    }
    .ember-power-select-trigger {
      width: 100%;
      min-height: 28px;
    }
    .datetimeflatpickr-wrapper .flatpickr-input {
      width: 100%;
    }
    .panel-body {
      .container {
        .input-group {
          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important; 
            }
          }
        }
      }
    }
  }
}

.width-inherit{
  width: inherit;
}
.margin-top-10 {
  margin-top: 10px;
}
