.cancel-booking-form-side-drawer {
    height: inherit;
    overflow-y: auto;
    .cancel-booking-form-layout {
      .sub-id{
        font-size: 14px;
      }
      .text-end{
        text-align: end;
      }
      .g-side-drawer-body {
        margin-top: 8px;
        background-color: white;
        .g-side-drawer-panel {
          .g-side-drawer-panel-body {
            position: relative;
            .view-impact-table-wrapper {
              width: 563px;
              overflow: auto;
              .ember-light-table {
                width: 850px;
              }
              .yellow-table-row {
                background-color: #F0ED92 !important;
              }
            }
            .g-side-drawer-panel-selector {
              &.bulk-trip {
                float: left;
                width: 43%;
              }
              h5 {
                width: 66px;
                margin: 0px 14px 0 15px;
              }
              .dropdown {
                .ember-power-select-trigger {
                  width: 155px;
                }
              }
            }
            .passanger-list-form {
              .cancel-trip-para {
                font-size: 14px;
                width: 341px;
                margin: 0 6px 15px 13px;
              }
              label{
                strong{
                  font-weight: 500;
                }
              }
            }
            input#override {
              margin-left: 10px;
            }
            label {
              margin: 5px 0px 7px 9px;
              font-size: 14px;
              font-weight: bold;
            }
            input[type="checkbox"] {
              vertical-align: bottom;
              position: relative;
              top: -4px;
              overflow: hidden;
            }

          }
           .g-side-drawer-panel-form {
             tr.sub-row{
               margin-bottom: 1em;
             }
             th.sub-head{
               font-size:14px;
             }
             td.delete-sub{
               div{
                 margin-bottom: 6px;
               }
               width:100%;
             }
           }

        }
      }


    }
 }
 .booking-container {
  margin: 20px;
  padding: 0px;
  .secondary-window {
    height: 50em;
  .secondary-window-modal {
  top: 30%;
  left: 30%;
  min-width: 30vw;
  height: 25%;
  }
  }
  .centered-scrolling-overlay {
    position: relative;
    min-height: 100vh;
    padding: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  /* basic modal style (an example, this is not necessary for the centering) */
  .centered-scrolling-container {
    position: relative;
    background-color: white;
     min-width: 60em;
     max-width: 650px;
     min-height: 30em;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-left:200px;
    box-shadow: 0px 4px 25px 4px rgba(0,0,0,0.30);
  }
  .sp-header{
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.sp-heading{
 padding: 3px 9px;
 font-weight: 500;
 font-size: 18px;
 background-color: #F6F6F6;
 border-radius: 7px 7px 0 0;
 .x-button{
   float: right;
   color: rgb(59, 57, 57);
 }
 .question-image{
   padding: 2px;
   float: right;
   color: #bdc5d4a8;
 }
}
  .btn-pri
  {
    justify-self: flex-end;
    border-radius: 5px;
    border: 1px solid white;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    width: 125px;
    padding: 0px 38px;
    text-decoration: none;
    background-image: linear-gradient(#334247, #171f22);
  }

}
