
.unscheduled-trips-popup{
    .confirm-dialog-unsuccessful{
      width: 95%;
      .popup-footer{
      padding: 0;
    }
    .tabs-btns{
      padding: 15px;
      ul{
        padding: 0;
        margin: 0;
        display: flex;
        li{
          list-style: none;
          margin-right: 40px;
          &.active{
            button{
            border-color: #7cb342;
            }
          }
          button{
            border: none;
            background-color: transparent;
            padding: 0;
            font-size: 16px;
            border-bottom: 3px solid transparent;
            padding-bottom: 5px;
            font-weight: 500;
            width: 75px;
            
          }
        }
      }
    }
    }
    .icon-btn{
      flex: 0 0 30px;
      padding: 0 5px 0 5px;
      margin-right: 8px;
      font-size: 18px;
      display: inline-block;
      background-color: inherit;
      border: none;
      opacity: 0.5;
      cursor: pointer;
      outline: none;
  }
  }