.add-edit-schedule-route-side-drawer {

  .g-side-drawer-body {
    padding-top: 8px;
    background-color: white;
  }
  .g-side-drawer-panel .g-side-drawer-panel-form td {
    padding-top: 5px;
  }
  .table-component.zone-table{
    max-height: 250px;
    margin-bottom: 10px;
  }
  .g-side-drawer-panel .ember-light-table .lt-row:nth-child(even) {
    background-color: white;
  }
  .table-component .table-component-body .ember-light-table .lt-body{
    font-size: 10px;
  }

  .table-component .table-component-header .table-component-title {
    font-size: 12px;
  }
  .table-component .table-component-body .ember-light-table .lt-head{
    font-size: 12px;
  }

  .assigned-zone-header-label {
    margin-left: 15px;
    font-size: 12px;
    font-weight: bold;
  }

  .container {
    background: $background-lighter;
    display: flex;



    .zone-button-wrapper {
      margin-left: 1.5rem;
      flex: 1 0 45%;
      button {
        @include button();
        height: 25px;
        border: 1px solid #B4B4B4;
        border-radius: 2px;
        background: $button-bg-grey;
        line-height: 1.1;
        cursor: pointer;

        span {
          flex: auto;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .input-group {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;

        label {
          font-size: 12px;
          flex: 0 0 45%;
          color: $dark-dds;
        }

        .ember-power-select-trigger {
          flex: 0 0 50%;
        }

      }
    }
  }
}
