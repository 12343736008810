            
// Variables
$checkbox-width: 14px;
$title-color: #303E44;

.dropdown-provider {
    text-align: left;
    font-family: $ui-font;
    font-size: $widget-body-font-size;
    color: black;
    line-height: normal;

    &.select {
        width: 150px;
        height: 30px;
        border: 1px solid rgba(38,50,56,0.2);
        border-radius: 2px;
        background-color: #FFFFFF;
        color: $title-color;
        cursor: pointer;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 5px 0 5px;
    
        &:hover {
            background-color: #F1F1F1;
        }
    
        .title {
            color: $title-color;
            font-weight: 500;
            flex-basis: 110px;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
        }

        span {
            color: $title-color;
            flex-grow: 0;
            i {
                color: $title-color !important;
            }
        }
    }
    
    &.options {
        position: fixed;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        list-style: none;
        box-sizing: border-box;	
        height: auto;
        border: 1px solid rgba(38,50,56,0.2);
        border-radius: 2px;
        background-color: #FFFFFF;
        overflow: auto;        
        max-height: 420px;
        min-width: 150px;
        padding: 0 5px 0 5px;

        .option {
            color: #303E44;
            font-family: $ui-font;
            padding: 6px 2px 0px 2px;       
            position: relative;

            // Checkboxes
            input[type="checkbox"] {
            position: absolute;
            left: -9999px;

                & + label {
                    position: relative;
                    display: inline-block;
                    padding: 0px 0px 0px ($checkbox-width + 8);
                    cursor: pointer;
                    width: 100%;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    // Default State
                    &:before {
                        display: block;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin: (-$checkbox-width/2) 0 0;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-size: 72px auto;
                        -webkit-background-size: 72px auto;
                        -moz-background-size: 72px auto;

                        box-sizing: border-box;	
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        background-color: #FFFFFF;
                    }
                }

                // Checked State
                &:checked + label {
                    &:before {
                        box-sizing: border-box;	
                        height: auto;
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #000;
                        color:#FFF;

                        font-family: FontAwesome;
                        font-weight: 200;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }

                // Disabled State
                &:disabled {
                    & + label {
                    cursor: not-allowed;

                        &:before {
                            background-color: #CCC;
                        }
                    }

                    // Disabled Checked
                    &:checked + label {
                        &:before {
                            background-color: #CCC;
                        }
                    }
                }


                // hover State
                &:hover + label {
                    &:before {
                        box-sizing: border-box;	
                        height: auto;
                        border: 1px solid rgba(38,50,56,0.2);
                        border-radius: 2px;
                        width: $checkbox-width;
                        height: $checkbox-width;
                        background-color: #f1f1f1;
                        color:#ccc;

                        font-family: FontAwesome;
                        font-weight: normal;
                        content: "\f00c";
                        font-size: 9px;
                        text-align: center;
                    }
                }

                // hover State checked
                &:checked + label:hover {
                    &:before {
                        background-color: #1b1b1b;
                        font-weight: 600;
                        color:#FFF;
                    }
                }
            }
        }
    }
}
