.maintenance {

  .container {
    padding: 0.5rem;
  }

  .maintenance-form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
  }

  .generic-panel {
    &.general {
      flex: 0 0 40%;
    }

    &.notes {
      margin-left: 0.5rem;
      flex: 0 0 59%;
    }
    &.unScheduled {
      flex: 1 0 auto;
      .container {
        max-width: calc(40% - 8px);
        margin-left: 0px;
      }
    }


    .panel-body {
      font-size: 12px;
      font-weight: 500;

      .container {
        padding-top: 0;

        .general-panel {
          label {
            flex: 0 0 32%;
            white-space: nowrap;
            margin-bottom: 1rem;
          }
        }

        .input-group {
          flex-wrap: nowrap;

          .ember-text-field {
            height: 25px;
            width: 100%;
          }
        }

        .input-group.input-box{
          div {
            flex: 0 0 67%;
          }
        }

        .ember-power-select-trigger {
          height: 25px;
          flex: 0 0 67%;
        }


      }
    }
  }

  .notes-panel {
    .ember-text-area {
      width: 98%;
      height: 160px;
    }
    .text-area-container{
      display: flex;
      height: 160px;
    }
  }

  .general-panel {
    .input-group-notes .ember-text-field {
      flex: 0 0 80%;
      height: 25px;
    }
  }

  .unScheduled-panel {
    label {
      flex: 0 0 32%;
      white-space: nowrap;
      margin-bottom: 1rem;
    }
  }

  .table-component .table-component-body {
    flex-basis: 210px;
  }

  .input-checkbox {
    position: relative;
    top: 6px;
    margin-right: 0.5rem;
  }
  .input-group-checkbox  {
    height: 25px;
    display: flex;
    margin-bottom: 0.5rem;

    .ember-text-field{
      flex: 0 0 64%;
    }
  } 
  .input-group.input-date{
    div {
      flex: 0 0 67%;
    }
  }

  .error{
    input{
      border: solid 2px $error-dds !important;
    }
  }
}
