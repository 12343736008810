.form-components-vehicle-selection {
  .vehicle-search-row {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;   
    line-height: 2;       

    input.vehicle-search-box {
      width: 17%;
      line-height: 0;
      border-color: lightgrey;
      border-right: 0;
      height: 26px;
      margin: 0 0 7px 8px;
    }

    button.vehicle-search-button {
      margin-right: 8px;
      display: inline-block;
      font-size: 18px;
      background-color: inherit;
      border: none;
      cursor: pointer;
      outline: none;
      border: 1px solid lightgrey;
      background-color: white;
      height: 26px;
      border-left: 0;
      line-height: initial;

      i.fa.fa-times-circle.active-button {
        opacity: 30%;
      }
    }
  }

  .vehicle-selection-detail {
    border: 1px solid #CCCED0;
  }
  
  .vehicle-selection-header {
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    margin: 0 0 3px 30px;
  }

  .ember-light-table {
    height: 200px;
  }
  
  background-color: $sidedrawer-table-background-color;
  border: 1px solid $sidedrawer-table-border-color;

  margin: 0 8px 8px 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  .g-side-drawer-panel-header {
    display: flex;
    height: 36px;
    cursor: pointer;
  }

  .g-side-drawer-panel-header-caret {
    flex: 0 0 30px;
    text-align: center;
    line-height: 36px;
  }

  .g-side-drawer-panel-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-collapsed {
    .g-side-drawer-panel-body {
      display: none;
    }
  }

  p {
    margin: 0 16px 18px 16px;
    font-size: 12px;
  }

  .g-side-drawer-panel-loader {
    display: flex;
    justify-content: center;
    align-items: center;

    // hack
    padding-top: 20px;
  }

  .g-side-drawer-panel-form {
    padding: 0 16px;
    border: 0;

    margin-bottom: 12px;

    tr {
      &.is-hidden {
        display: none;
      }
    }

    th {
      padding-right: 20px;

      font-size: 12px;
      text-align: right;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.left {
        text-align: left;
      }
    }

    &.compact {
      th {
        width: 120px;
        text-align: left;
        font-weight: normal;
      }
    }

    td {
      font-size: 12px;

      &.right-padded {
        padding-right: 8px;
      }
    }
  }

  .g-side-drawer-panel-table {
    font-size: 14px;
    border: none;

    thead {
      th {
        height: 24px;
        background-color: white;

        border-bottom: 2px solid $sidedrawer-table-background-color;
      }
    }

    tbody {
      max-height: 100px;
      overflow: scroll;
    }

    td {
      input[type=radio] {
        margin-right: 20px;
      }
    }

    tr {
      height: 24px;
    }

    td:first-of-type {
      padding-left: 10px;
    }

    td:last-of-type {
      padding-right: 10px;
    }

    tr:nth-child(odd) {
      td {
        background-color: white;
      }
    }
  }

  .ember-light-table {
    table {
      border: none;
      font-size: 14px;
    }

    .lt-head-wrap {
      padding-right: 0 !important;
    }

    .lt-head {
      th {
        height: 24px;
        background-color: white;

        border-bottom: 2px solid $sidedrawer-table-background-color;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .lt-body {
      background-color: white;
    }

    .lt-row {
      height: 23px;

      &:nth-child(even) {
        background-color: $sidedrawer-table-background-color;
      }

      &.is-selected {
        background-color: $table-row-selected-color !important;

        /* need to override striping for selected rows */
        &:nth-child(even) td {
          background: none;
        }
      }

      &.lt-is-loading, &.lt-no-data {
        height: 100%;

        td {
          height: 100%;
          background: none
        }
      }
    }

    .lt-cell {
      height: 24px;
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .needs-not-met {
        color: #E02020;
      }
    }

    // draw line for resize handle
    .lt-column-resizer {
      background: linear-gradient(to right,
                                  transparent 0%,
                                  transparent calc(50% - 1px),
                                  #ccc calc(50% - 1px),
                                  #ccc calc(50% + 0px),
                                  transparent calc(50% + 0px),
                                  transparent 100%) !important;
                                  height: 70%;
                                  margin-top: 4px;

    }
  }
}

