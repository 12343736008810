.contract-management {
  .container {
    padding: 0.5rem;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
  }

  .routeError input,
  .minRouteLenError input {
    border: solid 2px red !important;
  }
  .generic-panel {
    &.trim-slack,
    &.contract-management {
      width: 1000px;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
    .ember-power-select-trigger {
      width: 29% !important;
      font-weight: 100 !important;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding-top: 0;
        .input-group {
          align-items: baseline;
          flex-wrap: nowrap;

          .ember-text-field {
            flex: 0 0 30%;
            height: 25px;
            margin-right: 4rem;
          }
        }
        .input-group.input-box {
          div {
            width: 29%;
          }
        }

        .trim-slack-panel,
        .contract-management-panel {
          align-items: baseline;
          flex-wrap: nowrap;
          flex-direction: column;
          label {
            flex: 0 0 45%;
            white-space: nowrap;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
