.perform-stop-drawer {
  // not sure why this is necessary here, but it is
  height: inherit;
  overflow-y: auto;
  background-color: white;

  .unperform-title {
      font-size: small;
      font-weight: 600;
      margin: 8px 0px 0px 20px;
  }

  .unperform-message {
    font-size: smaller;
    font-weight: 500;
    margin: 2px 0px 8px 20px;
    color: darkred;
  }

  .perform-stop-layout {
    overflow-y: auto;
    background-color: white;
    margin-top: 3px;
  }

  .perform-break-drawer {
    background-color: white;
  }

  .locations-input {
    display: flex;
    align-items: flex-end;
  
    .cell {

      &.edit {
        padding-right: 4px;
        flex-basis: 250px;
        flex-grow: 1;
        flex-shrink: 1;
      }

      &.button {
        width: 20px;
        height: 22px;
        flex-grow: 0;
        font-size: $widget-button-font-size;
        color: $font-color;
        box-shadow: none;
        background-color: transparent;

        &:hover {
          background-color: $dds-primary;
          color: white;
        }

        &:disabled {
          color: $icon-light-color;
          border-color: $button-outline;
          background-color: $button-disabled-background;
          &:hover {
            color: $icon-light-color;
          }
        }
      }
    }
  }

  .row-hidden {
    display: none;
  }
  .g-side-drawer-body {
    margin-top: 8px;
    background-color: white;
  }

  .g-side-drawer-panel-body {
    position: relative;
  }

  .body-with-menu {
    display: flex;
  }

  .body-container {
    border-left: 1px solid #E5E6E7;
    margin-left: 4px;
    width: 100%;

    .panel-offset {
      margin-left: -4px;
    }
  }

  .stops-list {
    display: flex;
    flex-direction: column;
    min-width: 180px;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
  }

  .stops-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    height: 42px;
    border: 1px solid #CCCED0;
    background-color: #F5F5F7;
    font-size: 12px;
    text-align: left;
    line-height: 1;
    color: #313E44;

    .stops-list-item-section {
      .stops-list-item-header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .fa-check {
          color: #2B6A0D !important;
        }
      }
    }



    &.selected {
      background-color: #DFEBD0;
      color: #313E44 !important;
    }

    &:disabled {
      color: #92999D;
    }
  }

  .sub-header-container {
    display: flex;

    .perform-stop-progress-nav {
      .action-button {
        display: block;
        height: 26px;
        border: 1px solid #B4B4B4;
        border-radius: 2px;
        font-size: 14px;
        color: white;
        line-height: 1.4;
        padding-left: 24px;
        padding-right: 24px;
        margin-left: 8px;
        background: linear-gradient(#449324, #2F6B16);

        &:disabled {
          color: #AEAEAE;
          background: linear-gradient(#FDFDFD, #E1E1E1);
          cursor: not-allowed;
        }
      }
    }
  }

  .perform-stop-progress-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .summary {
    margin: 8px 8px 8px 24px;

    h5 {
      font-size: 14px;
      font-weight: bold;
    }

    p {
      font-size: 12px;
    }
  }

  .btn-add-travel-need {
    position: absolute;
    top: -29px;
    right: 18px;
    height: 23px;
    width: 25px;

    text-align: center;
    font-size: 10px;
    line-height: 23px;

    background-color: white !important;
  }

  .travel-needs-table {
    border: none;
    tr td {
      padding-bottom: 5px;
    }
  }

  .passenger-option-label {
    font-size: 12px;
    font-weight: bold;

    margin-left: 4px;
    margin-right: 20px;
  }

  .button {
    background: #FFFFFF;
    border: 1px solid rgba(38,50,56,0.2);
    border-radius: 2px;
    box-shadow: inset 0 1px 0 0 #FFFFFF;
  }
}

.travel-needs-picker {
  font-size: 12px;
  font-weight: 700;

  .pca-companion-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .service-animal .pca {
      span {
        margin-left: 3px;
      }
    }

    .companion {
      display: flex;
      font-size: 12px;

      span {
        margin-right: 6px;
      }

      input[type="number"] {
        width: 55px;
        height: 20px;
        padding: 7px;
      }
    }
  }

  .travel-needs-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 14px 0px 14px -11px;

    .travel-needs-row-ambulatory {
      display: flex;
      
      &.is-not-enough {
        border: 1px solid red;
      }

      span {
        margin-right: 6px;
      }

      input[type="number"] {
        width: 91px;
        height: 20px;
        padding: 7px;
      }
    }

    .travel-needs-row-wheelchair {
      display: flex;
      
      &.is-not-enough {
        border: 1px solid red;
      }
      
      span {
        margin-right: 6px;
      }

      input[type="number"] {
        width: 91px;
        height: 20px;
        padding: 7px;
      }
    }

    span.travel-needs-row-value {
      margin-left: 2px;
    }
  }
}
