.edit-trip-side-drawer {
  .g-side-drawer-body {
    background-color: white;
    margin-top: 10px;
    .g-side-drawer-panel .g-side-drawer-panel-title {
      font-size: 12px;
      font-weight: 600;
    }
    .form-widget-body{
      padding-right: 5px;
      padding-left: 5px;
      .form-edit-cell.address-search{
        .cell.button{
          height: 23px;
        }
      }
    }
    .cell.edit {
      min-width: 100px;
      font-size: 12px;
      flex: 1 1 30%;
    }
  }
}
