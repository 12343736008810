.reconcile-column-editor {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(38, 50, 56, 0.1);
  padding-top: 105px;
  z-index: 9999;
  align-items: flex-start;
  .column-editor-box {
    width: 525px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    h2 {
      background: #171f22;
      background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
      background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
      background: #f5f5f7;
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
      margin: 0;
      color: #000;
      padding: 0 15px;
      height: 40px;
      font-size: 18px;
      line-height: 40px;
      font-weight: 600;
      border-radius: 4px 4px 0 0;
      .btn-close {
        opacity: 0.4;
        img {
          width: 15px;
        }
      }
    }
    p {
      font-size: 14px;
      margin: 0;
      line-height: 19px;
      font-weight: 600;
    }
    .popup-footer {
      padding: 0px;

      .editColum-settings th,
      .editColum-settings td {
        padding-left: 30px !important;
        padding-right: 30px !important;
        font-weight: bold;
        font-size: 14px;
      }
      .editColum-settings .lt-head tr th:last-child {
        text-align: center !important;
      }
      .editColum-settings .lt-head-wrap {
        padding-right: 0px !important;
      }
      .column-widget .column-widget-body {
        flex-basis: auto;
      }
      p {
        color: #eb6e6e;
      }
      button {
        width: 113px;
        height: 27px;
        border: 1px solid #fff;
        border-radius: 3px;
        background: #171f22;
        background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
        background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
        background: linear-gradient(to bottom, #334247 0%, #171f22 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        font-weight: 600;
        margin: 0 3px;
        line-height: 25px;
      }

      .ok-btn {
        text-align: right;
        margin: 5px 0px 0px;
      }

      .ok-button-text {
        height: 26px;
        background: #f5f5f7;
        width: 100px;
        color: #363b42;
      }
    }
    .dialog-close {
      margin-top: 4px;
      color: #a7b4b9;
    }
    .column-table-wrapper {
      border: 1px solid #ccced0;
      border-radius: 4px;
      background-color: #ffffff;
      h6 {
        color: #303e44;
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
      }
    }
  }

  .column-editor-box .column-widget-button {
    border: none;
    color: #a8acaf;
    background-color: #f5f5f7;
  }

  .column-widget-body-columne-editor {
    // must set `flex-basis` or else light table will have incorrect height
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    overflow: auto;
    font-size: $widget-body-font-size;
    background-color: $table-background-color;

    .ember-light-table {
      overflow: visible;
      font-feature-settings: "tnum";
      width: fit-content;

      input[type="checkbox"] {
        font-size: 20px;
      }

      .lt-head-wrap {
        background-color: $table-column-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        overflow: visible;
        position: sticky;
        top: 0;
      }

      .lt-head {
        width: 100%;
        border-left: solid $table-header-left-border-width
          rgba(255, 255, 255, 0);

        th {
          padding: 2px 2px 2px 2px;;
          th {
            padding: 4px;
          }
          font-weight: 700;
          color: $font-color;
          opacity: 0.8;

          &:first-child {
            padding-left: 7px;
            input {
              float: left;
            }
          }
        }
        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #ccc calc(50% - 1px),
            #ccc calc(50% + 0px),
            transparent calc(50% + 0px),
            transparent 100%
          ) !important;
          height: 70%;
          margin-top: 4px;
        }
      }

      // .lt-column {
      //   &:focus-within {
      //     background-color: $table-cell-selected-color;
      //   }
      // }

      .otp-danger .highlightable {
        color: $otp-danger;
      }

      .otp-warning .highlightable {
        color: $otp-warning;
      }

      .lt-row {
        border-left: solid $table-row-left-border-width rgba(255, 255, 255, 0);

        &:nth-child(2n-1) {
          background-color: $table-row-alt-background-color;
        }
        &.is-locked {
          background-color: lightgrey !important;
          color: grey;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }

        &.drag-target-above {
          border-top: 15px solid #ccf;
        }

        &.drag-target-below {
          border-bottom: 15px solid #ccf;
        }

        &.drag-target {
          background-color: #ccf;
        }

        &.otp-warning {
          border-color: $otp-warning;
        }

        &.otp-danger {
          border-color: $otp-danger;
        }
      }

      .lt-cell {
        padding: 3px 5px 2px 2px; // same here: we just want the top cell to have padding
        &.priorityId {
          display: none;
        }

 

        .lt-cell {
          padding: 0 0 0 0;
        }
      }

      .lt-body-wrap {
        margin-top: 3px;
      }

      .lt-body {
        color: $table-body-text;
        font-weight: 500;
        margin-top: 3px;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 2px;
        // visibility: hidden;
        line-height: 20px;
      }
    }

    .column-widget-editable-cell {
      width: 100%;
    }

    .cell-text-extension {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #657175;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}


