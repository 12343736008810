.dashboard-picker {
  .dashboard-picker-div{
    height: calc(100% - 50px);
    overflow: auto;
    padding: 0px 40px;
    margin-top: 20px;
  }
  .dashboard-picker-label{
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 600;
  }
  .dashboard-picker-section{
    
    margin-bottom: 50px;

    .dashboard-picker-section-scroll {
      overflow: auto;
      margin-left: 13px;
      margin-bottom: 10px;
    }

    /* width */
    .dashboard-picker-section-scroll::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .dashboard-picker-section-scroll::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 1;
    }

    /* Handle */
    .dashboard-picker-section-scroll::-webkit-scrollbar-thumb {
      background: #7f8385;
    }

    /* Handle on hover */
    .dashboard-picker-section-scroll::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .dashboard-picker-section-title {
    font-weight: bold;
    font-size: 0.8em;
    background-color: #eee;

    align-items: center;
    padding: 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .dashboard-picker-item {
    display: flex;
    align-items: center;
    height: 44px;
    color: white;
    margin: 3px 13px 3px 0;
    padding: 5px;
    border-bottom: 1px solid #ffffffa3;

    &.is-open.is-clean {
      color: #63c915;
    }

    .spacer {
      flex: 1 0 1px;
    }

    button {
      justify-self: flex-end;
      border-radius: 5px;
      border: 1px solid white;
      display: inline-block;
      cursor: pointer;
      color: white;
      font-size: 14px;
      font-weight: 400;
      height: 26px;
      width: 125px;
      padding: 0px 38px;
      text-decoration: none;
      background-image: linear-gradient(#334247, #171f22);

      &.is-open {
        border: 1px solid #63c915;
      }
    }
    button:hover {
      border: 1px solid #63c915;
    }
    button:active {
      border: 1px solid #63c915;
      position: relative;
      top: 1px;
    }
  }
}

.dashboard-picker-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  background-color: #263238;
  border-radius: 4px 4px 0 0;
  border-bottom: 2px solid #171f22;

  .divider {
    height: 16px;
    margin: 0 24px;
  }

  .tab {
    width: 50%;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    padding-left: 24px;
    padding-right: 24px;
    color: #9b9b9b;
    outline: none;
    padding-top: 3px;
    border-color: #263238;
    background-color: #263238;
    white-space: nowrap;
    border-width: 0 0 0 0;
    -webkit-appearance: none;

    &.active {
      color: #ffffffe3;
      border-width: 0 0 2px 0;
      border-bottom-color: #63c915;
    }
  }

  .dashboard-picker-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    background-color: #263238;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #171f22;

    .divider {
      height: 16px;
      margin: 0 24px;
    }

    .tab {
      width: 50%;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      padding-left: 24px;
      padding-right: 24px;
      color: #9b9b9b;
      outline: none;
      padding-top: 3px;
      border-color: #263238;
      background-color: #263238;
      white-space: nowrap;
      border-width: 0 0 0 0;
      -webkit-appearance: none;

      &.active {
        color: #ffffffe3;
        border-width: 0 0 2px 0;
        border-bottom-color: #63c915;
      }
    }
  }
}
