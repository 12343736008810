.reconcile-search-passenger {
  .btn-gray {
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    min-width: 98px;
    height: 26px;
    color: #303e44;
    font-weight: 500;
    padding: 0 10px;
    background-color: #f7f7f7;
    font-size: 14px;
    background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
    line-height: 1.2;
  }
.dialog-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(38, 50, 56, 0.1);
  padding-top: 105px;
  z-index: 9999;
  align-items: flex-start;
}

.dialog-box .confirm-dialog-unsuccessful {
    width: 90%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
h2 {
  background: #f5f5f7;
  margin: 0;
  color: #000;
  padding: 0 15px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  border-radius: 4px 4px 0 0;
}
.search-passenger-table-wrapper {
  border: 1px solid #ccced0;
  border-radius: 4px;
  background-color: #ffffff;
}
.dialog-close{
  margin-top: 4px;
  color: #a7b4b9;
}
}
.dialog-box .popup-footer {
  padding: 15px 20px;
}

.dialog-box .header-fields-group label {
  font-size: 13px;
  font-weight: 600;
}
.dialog-box .header-fields-group > div {
  padding: 0 15px 0 15px;
}
.dialog-box .header-fields-group .v-divider:before {
  width: 2px;
  height: 22px;
  background-color: #212529;
 }
.lt-head th, .lt-body td{
   width:27px;
   padding-top: 2px !important;
   padding-right: 1px !important;
   padding-bottom: 2px !important;
   padding-left: 12px !important;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .lt-head th:last-child,.lt-body td:last-child 
 {
  width:80px;
}
.lt-body .lt-cell{
  padding-top: 3px !important;
  padding-right: 3px;
  padding-bottom: 2px !important;
  padding-left: 11px !important;
}
}
