.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center {
  .search-modal-styles-overlay {
        padding: 0;
        border-radius: 1;
        z-index: zindex(action-modal);
        outline: none;
        &.ember-modal-overlay.translucent {
          background-color: rgba(38,50,56,0.2);
        }

      .ember-modal-dialog {
          background-color: transparent;
          box-shadow: none;
          z-index: zindex(action-modal);

        .search-modal {
          background: $light-dds;
          border-radius: 10px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
          max-width: 600px;
          &.vehicle-search {
            width: 600px;
          }

          .search-modal-header {
            height: 40px;
            display: flex;
            align-content: baseline;
            justify-content: space-between;
            background: $background-lighter;
            font-size: $font-size-base-dds;
            border-radius: 10px 10px 0 0;
            padding: 0.5rem;
            @include box-shadow(0, 2px, 4px, rgba(148,148,148,0.5));

            .table-component-content {
              z-index: calc(zindex(action-modal) + 1);
            }

            h5 {
              font-size: $font-size-base-dds;
              line-height: 22px;
            }

            .search-modal-buttons {
              display: flex;
              align-items: center;
              padding: 0 0.5rem 0 0.5rem;

              i {
                @include icon($icon-light-color);
                font-size: 1em;
                margin-left: 0.5rem;
                opacity: 1;
              }
            }



          }

          .search-modal-body {
            padding: 1rem;
            font-size: small;
            font-weight: 100;

            .search-input {
              height: 30px;
              display: flex;
              justify-content: space-between;
              align-content: baseline;
              margin-bottom: 0.5rem;
              border: 1px solid #979797;
              border-radius: 2px;
              box-shadow: 0 2px 4px 0 rgba(135,135,135,0.5);
              // the search box needs a little extra margin for focus border
              input {
                font-size: $font-size-base-dds;
                font-weight: $font-weight-base-dds;
                padding: 0.5rem;
                border: transparent;
                outline: none;
                flex: 1 0 50%;
              }

              button {
                font-size: $font-size-sm-dds;
                background-color: inherit;
                border: none;
                color: $icon-light-color;
                opacity: 0.5;
                cursor: pointer;
                outline: none;

                &:last-child {
                  margin-right: 0;
                }
                // height: 25px;

                &:focus {
                  box-shadow: 0 0 1px 1px $table-cell-selected-color;
                  border-radius: 4px;
                  opacity: 1;
                }

                &:hover {
                  color: dds("700");
                  transition: color 0.2s;
                  opacity: 1;
                }
              }
            }

            .search-table {
              .table-component {
                overflow: inherit;
              }
            }

            .search-modal-button{
              display: flex;
              justify-content: flex-end;
              padding: 10px 0px 10px 10px;

              button.cancel {
                margin-right: 1rem;
                height: 1.6rem;
              }
              button {
                @include button;
                height: 26px;
                width: 101px;
                border: 1px solid #B4B4B4;
                border-radius: 2px;
                background: $button-bg-grey;

                span {
                  flex: auto;
                  font-size: 14px;
                  font-weight: 500;
                }
              }

              .tooltip-running {
                min-width: 98px;
              }
            }
          }
        }
      }
  }
}
