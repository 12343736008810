$generic-panel-width: calc(100% - 0.25rem);

.no-show-reason-categories {
  label {
    margin: 0;
  }
  .container {
    padding: 0.5rem;

    .no-show-reason-table .table-component-body .ember-light-table {
      .lt-head-wrap {
        padding-right: 0 !important;
      }
    }
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
  }

  .left-form-panel-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 $generic-panel-width;
  }

  .error {
    input,
    .ember-power-select-trigger {
      border: solid 2px $error-dds !important;
    }
  }

  .generic-panel {
    position: relative;
    flex: 0 0 $generic-panel-width;

    &:first-child {
      margin-right: 0.5rem;
    }

    .ember-power-select-trigger {
      max-width: 50%;
      font-weight: 100 !important;
      line-height: 20px;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding-top: 0;

        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          height: 30px;

          .ember-text-field {
            flex: 0 0 50%;
            height: 25px;
            margin-right: 4rem;
          }
        }

        .input-group.input-box {
          div {
            flex: 0 0 50%;
          }
        }

        .main-information-panel {
          align-items: baseline;
          flex-wrap: nowrap;
          flex-direction: column;

          label {
            flex: 0 0 25%;
            white-space: nowrap;
          }

          .ember-power-select-trigger {
            ul {
              li {
                display: none;
              }
            }
            .ember-power-select-trigger-multiple-input {
              min-width: 100%;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .panel-header {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .panel-header-buttons {
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1.1;
      cursor: default;
      .btn {
        padding: 0 5px;
        font-size: 12px;
        margin-left: 7px;
        i {
          margin-right: 0;
        }
      }
    }
  }
}
