.schedule-trip-sidebar {
  .g-side-drawer-panel {
    .g-side-drawer-panel-header {
      padding: 3px 15px 0;
    }
  }

  .g-side-drawer-panel-body.p-2 {
    padding: 5px 0 0 0 !important;

    .trip-information {
      th {
        font-weight: normal;
      }
    }
  }

  .g-side-drawer-scroll-container {
    height: auto !important;
    overflow: hidden;
  }
  .trip-transfer-tabs {
    justify-content: flex-start;
    margin-bottom: 25px;
  }
  .g-side-drawer-panel {
    margin: 0 8px 8px 8px;
  }
  .view-impact-table-wrapper{
    background-color: #fff;
  }
}

