$table-row-danger-color: rgba(179, 49, 7, 0.27);
.content-wrapper {
  .Grid {
    border: 1px solid #e0e0e0;
  }
  .Cell {
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-right: transparent;
    border-bottom: transparent;
    outline: none;
    background: white;
    color: $g-sidedrawer-button-color;
    font-weight: 500;
    padding: 2px;
    padding-left: 5px;
    .column-label {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn-cog {
      background-color: inherit;
      border: none;
      color: #9DABB0;
      opacity: 0.5;
      cursor: pointer;
      outline: none;
      font-size: 15px;
      &:hover {
        color: #689F38;
        transition: color 0.2s;
        opacity: 1;
      }
    }
  }
  .HeaderCell {
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    background-color: $g-sidedrawer-table-background-color;
    font-weight: 700;
    color: $g-sidedrawer-font-color;
    opacity: 0.8;
    padding: 2px;
  }
  .HeaderCell .column-label {
    flex-basis: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .HeaderCell .column-controls {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12px;
    padding-right: 4px;
    white-space: nowrap;
  }
  .FocusedCell {
    background-color: #e0e0e0;
    font-weight: bold;
  }
  .Radio {
    margin-left: 0.5rem;
  }
  .checkboxLabel {
    display: flex;
    align-items: center;
  }
  .checkbox {
    margin-right: 5px;
  }
  .widget-container {
  }
  .selected-row {
    background-color: $table-row-selected-color;
  }
  .selected-column {
    background-color: $table-cell-selected-color;
  }
  .even-cell {
    background-color: $table-row-alt-background-color;
  }
  .odd-cell {
    background-color: white;
  }
  .otp-danger {
    color: $otp-danger;
  }
  .otp-warning {
    color: $otp-warning;
  }
  .otp-ontime {
    color: $otp-ontime;
  }
  // Related to Resize Column header
  .DragHandle {
    right: 0;
    z-index: 2;
    cursor: col-resize;
    color: black;
  }
  .DragHandle:hover {
    background-color: transparent;
  }
  .DragHandleActive,
  .DragHandleActive:hover {
    color: #0b6fcc;
    z-index: 3;
  }
  .DragHandleIcon {
    cursor: col-resize;
    position: absolute;
    display: inline-block;
    width: 5px;
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent calc(48% - 1px),
      #ccc calc(48% - 1px),
      #ccc calc(50% + 0px),
      transparent calc(50% + 0px),
      transparent 100%
    ) !important;
    height: 70%;
    margin-top: 3px;
  }
  .HeaderCell i {
    float: none;
    margin-left: auto;
    margin-right: 0;
    padding-left: 2px;
    line-height: 20px;
  }
  // Related to Context Menu
  .menu {
    //width: 150px;
    z-index: 1000;
    /* background-color:rgb(140, 226, 144); */
    background-color: #eee;
    box-shadow: 0 0px 2px 0px $widget-dropdown-shadow;
    cursor: pointer;
    font-family: $ui-font;
    font-size: $widget-body-font-size;
    position: absolute;
    color: black;
    padding: 0px 0px;
    font-weight: bolder;
  }
  .menu .menu-options {
    list-style: none;
    padding: $widget-header-mobile-padding 0px;
    font-family: $ui-font;
    line-height: 0.5;
    color: black;
    text-align: left;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }
  .menu-option {
    font-weight: 400;
    font-size: $widget-body-font-size;
    cursor: pointer;
    font-family: $ui-font;
    color: black;
    padding: $widget-header-mobile-padding 40px $widget-header-mobile-padding 20px;
  }
  .menu-option:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: $dds-200;
    color: white;
  }
  .cell-checkbox {
    position: absolute;
    left: 5px;
    cursor: pointer
  }
  .otp-late-color {
    color: $otp-danger;
  }
  .otp-danger-color {
    color: #E3621B;
  }
  .first-column {
    border-left: solid;
    border-left-color: transparent;
    border-left-width: 5px;
  }
  .otp-on-time-first-column {
    border-left: solid;
    border-left-color: $otp-ontime;
    border-left-width: 5px;
  }
  .otp-in-danger-first-column {
    border-left: solid;
    border-left-color: $otp-warning;
    border-left-width: 5px;
  }
  .otp-late-first-column {
    border-left: solid;
    border-left-color: $otp-danger;
    border-left-width: 5px;
  }
  .is-locked {
    background-color: lightgrey !important;
    color: grey;
  }

  .cell-focued {
    background-color: #c4f7c4;
  }
  .arrow-key-stepper {
    height: 100%;
  }
  .ReactVirtualized__Grid,
  .empty-widget .ReactVirtualized__Grid__innerScrollContainer {
    background-color: $g-sidedrawer-table-background-color;
    box-shadow: 0 1px 5px rgba(38, 50, 56, 0.2);
    z-index: 1;
  }
  .ReactVirtualized__Grid[tabindex="0"] {
    background-color: transparent;
    box-shadow: none;
    z-index: 0;
  }
  .ReactVirtualized__Grid:focus {
    outline: none;
  }
  .empty-widget .ReactVirtualized__Grid .even-cell,
  .ReactVirtualized__Grid .even-cell {
    background-color: $g-sidedrawer-table-background-color;
  }
  .ReactVirtualized__Grid[tabindex="0"] .even-cell {
    background-color: $table-row-alt-background-color;
  }
  .ReactVirtualized__Grid[tabindex="0"] .danger-row,
  .empty-widget .ReactVirtualized__Grid .danger-row,
  .danger-row {
    background-color: $table-row-danger-color;
  }
  .ReactVirtualized__Grid[tabindex="0"] .danger-row.selected-row,
  .empty-widget .ReactVirtualized__Grid .danger-row.selected-row,
  .danger-row.selected-row {
    background-color: $table-row-selected-color;
  }
  .ReactVirtualized__Grid[tabindex="0"] .danger-row.selected-column,
  .empty-widget .ReactVirtualized__Grid .danger-row.selected-column,
  .danger-row.selected-row.selected-column {
    background-color: $table-cell-selected-color;
  }
  .disabled_checkbox{
    cursor: not-allowed;
  }
  .enabled_checkbox{
    cursor: auto;
  }

}

.global-dropdown-context {
  margin-top: -1px;
  box-shadow: 0px 0px 2px 0px #7d7a7a;
  font-family: "AvenirNext";
  font-size: 12px;
  background-color: #e6e7eb;
  color: black;
  div {
    padding: 5px 10px;
  }
  div:hover {
    cursor: pointer;
    background-color: #689f38;
    color: white;
  }
}

// Hide vehicles-widget gear icon on tablet.
.vehicle-widget-gear-icon .btn-cog {
  display: none !important;
}
