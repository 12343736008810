.is-under-capacity {
  color: $danger-color;
}

.assign-rvd-side-drawer {
  height: inherit;
  .g-side-drawer-body {
    padding-top: 8px;
    background-color: $white;

    .ember-light-table {
      height: 200px;

      .lt-row {
        &.is-disabled {
          background-color: lightgrey !important;
          color: grey;
        }
      }

    }
  }

  .ember-light-table .lt-column .lt-sort-icon {
    margin-right: 10px;
  }

}

.table-vehicle-header-column {
  display: flex;

  .column-label {
    flex-basis: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 23px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .column-controls {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12px;
    padding-top: 4px;
    white-space: nowrap;
  }
}

