.reconcile-widget-base {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .g-side-drawer-body {
    display: block;
    margin-top: 8px;
    background: white;
    .view-impact-table-wrapper .ember-light-table .lt-row.is-selected {
      background-color: #f0ed92 !important;
    }
    .g-side-drawer-panel-form{
      td, th{
        //padding: 2px 5px;
        padding-bottom: 2px;
        font-size: 14px;
      }
    }
    
  }
  .ember-modal-dialog {
    .ember-power-select-trigger{
      line-height: 1.3;
      font-weight: normal;
    }
  }

  .g-side-drawer-sub-header {
    font-size: 13px;
  }

  .map-inline-button{
    width: 20px;
    height: 21px;
    padding: 0 2px 0px 3px;
    font-size: 13px;
    margin: 0 0 0 4px;
  }

  .mtm-38 {
    margin-top:-38px !important;
  }

  .form-widget-body {
    overflow: auto;
  }

  .g-side-drawer-scroll-container {
    height: 200px !important
  }

  .trip-transfer-tabs {
    .tab {
      background-color: #ffffff !important;
    }
  }

  .toggle-switch .label  {
    font-weight: 600;
  }

  .errors {
    padding: 15px 15px 0 22px;
    list-style: none;
    text-align: left;
    font-size: 14px;
    color: #eb3947;
  }

  .places-button {
    margin-left: 8px;
    padding: 0 7px;
    height: 23px;
    border-color: #cdd4d6 !important;
    background-color: #fff !important;
    color: #303e44 !important;
  }
  .input-icon{
    position: absolute;
    font-size: 12px;
    right: 10px;
    top: 4px;
  }
  .input-plus-icon{
    top: -37px;
    position: absolute;
    right: 3px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    font-size: 13px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
  }
  .view-impact-table-wrapper {
    overflow: auto;
  }

  .column-widget .column-widget-body .ember-light-table .lt-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ember-power-select-multiple-option {
  border: none;
  background-color: #f5f5f7;
  padding: 0 10px 0 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 20px;
  span{
    opacity: 1 !important;
    font-size: 15px;
    line-height: 1;
    margin-right: 3px;
  }
}


.dialog-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(38, 50, 56, 0.1);
  padding-top: 105px;
  z-index: 9999;
  align-items: flex-start;
  overflow: auto;
  &.search-popup {
      .confirm-dialog-unsuccessful {
      width: 1000px;
      }
      .column-widget-body {
          flex-basis: auto;
    }
  }
}
.success-verify-text {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  border-radius: 5px;
  border: 1px solid #CCCED0;
  padding: 10px;
  font-weight: bold;
}

.confirm-dialog-unsuccessful {
  width: 755px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  h2 {
    background: #171f22;
    background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
    background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
    background: #f5f5f7;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
    margin: 0;
    color: #263238 !important;
    padding: 0 15px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 600;
    border-radius: 4px 4px 0 0;
    .btn-close {
      opacity: 0.4;
      img {
        width: 15px;
      }
    }
  }
  p {
    font-size: 14px;
    margin: 0;
    line-height: 19px;
    font-weight: 600;
  }
  .popup-footer {
    padding: 15px 20px;

    p {
      color: #eb6e6e;
    }
    .btn-dark {
      width: 113px;
      height: 27px;
      border: 1px solid #fff;
      border-radius: 3px;
      background: #171f22;
      background: -moz-linear-gradient(top, #334247 0%, #171f22 100%);
      background: -webkit-linear-gradient(top, #334247 0%, #171f22 100%);
      background: linear-gradient(to bottom, #334247 0%, #171f22 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
      color: #fff;
      font-size: 14px;
      padding: 0 10px;
      font-weight: 600;
      margin: 0 3px;
      line-height: 25px;
    }

    .ok-btn{
      text-align: right;
      margin: 5px 0px 0px;
    }

    .ok-button-text{
      height: 26px;
      background: #f5f5f7;
      width: 100px;
      color: #363B42;
    }
  }
  .dialog-close {
    margin-top: 4px;
    color: #a7b4b9;
  }
  .verification-results {
    border: 1px solid #ccced0;
    border-radius: 4px;
    background-color: #ffffff;
    overflow: hidden;
    h6 {
      color: #303e44;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
    }
  }
  .custom-input  {
    font-size: 12px;
    display: block;
    width: 160px;
    padding: 3px 7px 0 7px;
    line-height: 1.5;
    color: #303e44;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #cdd4d6;
    border-radius: 3px;
    outline: none;
    box-sizing: border-box !important;
    &.date-input{
      width: 100px;
    }
  }
  .custom-label {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 0;
    white-space: nowrap;
  }
  .verify-route-tbl{
    flex-basis: 150px !important;
  }
}
.confirm-dialog h2 {
  background: #171F22;
  background: -moz-linear-gradient(top, #334247 0%, #171F22 100%);
  background: -webkit-linear-gradient(top, #334247 0%,#171F22 100%);
  background: linear-gradient(to bottom, #334247 0%,#171F22 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
  margin: 0;
  color: #fff;
  padding: 0 15px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  border-radius: 4px 4px 0 0;
}
.confirm-dialog {
  position: absolute;
  width: 438px;
  min-height: 173px;
  background-color: #263238;
  margin: 0 auto;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.5);
  overflow: hidden;
}
.top_0 {
  top: 0px;
}
.confirm-dialog p {
  color: #fff;
  font-size: 14px;
  padding: 20px 30px 10px;
  margin: 0;
  line-height: 19px;
  font-weight: 600;
}
.confirm-dialog .popup-footer {
  text-align: right;
  padding: 15px 30px;
}
.confirm-dialog .popup-footer .btn-dark {
  width: 113px;
  height: 27px;
  border: 1px solid #fff;
  border-radius: 3px;
  background: #171F22;
  background: -moz-linear-gradient(top, #334247 0%, #171F22 100%);
  background: -webkit-linear-gradient(top, #334247 0%,#171F22 100%);
  background: linear-gradient(to bottom, #334247 0%,#171F22 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324045', endColorstr='#182124',GradientType=0 );
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
  font-weight: 600;
  margin: 0 3px;
  line-height: 25px;
}
.confirm-dialog .dialog-close {
  position: absolute;
  top: 8px;
  right: 15px;
  color: #fff;
}
.p-left-25{
  padding-left: 25px !important;
}
.p-left-100{
  padding-left: 100px !important;
}

input[type=number].no-spinner::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
.reconcile-add-new-route{
  .g-side-drawer-panel-form th{
    width: 148px;
  }
}
.route-edit-tbl tbody tr td:last-child {
  padding-right: 14px !important;
}
.color-danger{
  color:#E02020;
}
.verification-error{
  font-size: 16px;
  h6{
    font-weight: bold;
    margin-bottom: 5px;
  }
  span{
    margin-bottom: 15px;
    font-weight: normal;
  }
}
.btn-gray {
  border: 1px solid #b4b4b4;
  border-radius: 2px;
  min-width: 98px;
  height: 26px;
  color: #303e44;
  font-weight: 500;
  padding: 0 10px;
  background-color: #f7f7f7;
  font-size: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  line-height: 1.2;
}
button:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.address-select {
  width: 366px;
  .location-box {
    > div {
      width: 366px;
    }
  }
}