.dot-inspection-view {

  input,
  textarea {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 1.75;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .error{
    input{
      border: solid 2px $error-dds !important;
    }
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;

    .details {
      flex: 0 0 40%;
    }

    .failed-dot {
      flex: 1 1 60%;
      margin-left: 0.5rem;
    }

    .notes {
      flex: 1 1 auto;
    }
  }

  .generic-panel {
    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 2rem 0 2rem;
        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }

          .ember-power-select-trigger {
            flex: 1 1 30%;
            height: 25px;
          }

          .datetimeflatpickr-wrapper {
            flex: 1 1 30%;
            input {
              height: 25px;
              width: 100%;
            }
          }

          .ember-text-field-holder {
            flex: 1 1 30%;
            .ember-text-field {
              height: 25px;
              width: 100%;
            }
          }

          .ember-checkbox {
            flex: 0 0 25%;
            input {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}
