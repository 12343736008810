// .is-selected {
  .table-check-box-cell.is-touch-device {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    // margin-top: -4px;
    input {
      margin-top: 1px;
    }
    .fa {
      // &.is-checked {
      //   color: #3b88fd;
      // }
      // margin-top: 2px;
      float: right;
      display: block;
      color: #999;
      cursor: pointer;
      font-size: 18px;
      margin-right: 3px;
    }
  
    input {
      float: left;
    }
  }
  .vehicle-widget-gear-icon.vehicles-widget .table-check-box-cell.is-touch-device {
      .fa {
        display: none !important;
      }
    }

.table-check-box-cell {
  text-align: center;
  display: flex;
  .fa {
    display: none;
  }
  input {
  }
  input:focus {
   // see `.lt-cell:focus-within` in `column-widget.scss` for focus styling
   outline: none;
  }
}
