// secondary menu (workspace navigation) background colors
$workspace-menu-background-light: $background-lighter;
$workspace-menu-background-dark: black;


.workspace-nav-wrapper {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  flex: 0 0 $workspace-nav-height;
  z-index: 100;

  .workspace-navbar {
    display: flex;
    height: $workspace-nav-height;
    background-color: $background-lighter;
    color: dds-greys("800");
    padding: 5px 0 5px 20px;
    font-weight: 500;
    font-size: 14px;

    &.reoptimize-in-progress {
      background-color: $info-dds;
      color: $white;
    }
    &.is-exported {
      background-color: $info-dds;
      color: $white;
    }

    .workspace-logo {
      margin-right: 2rem;
      white-space: nowrap;
      .fa {
        color: #a7b3b9;
        font-weight: normal;
      }
      .fa-th:before {
        content: "\f00a";
      }
    }

    .datetimeflatpickr-wrapper {
      flex: 0 0 200px;
      .flatpickr-input {
        width: 100px;
        height: 22px;
        font-size: 12px;
      }
    }

    .re-optimize-btn {
      @include button;
      height: 22px;
      font-size: 12px;
      font-weight: 500;
    }

    .reoptimize-information {
      display: flex;
      align-items: baseline;
      flex: 1 1 auto;
      p {
        margin: 0;
      }
    }

    #header-left {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
    #header-center {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
    #header-right {
      display: flex;
      align-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
  }
}

.header-fields-group {
  width: 100%;
  justify-content: flex-start !important;
  align-items: center !important;
}

.header-fields-group > div {
  display: flex;
  align-items: center;
  padding: 0 15px 0 5px;
}

.header-fields-group .v-divider:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 28px;
  background-color: #d4d6d7;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-fields-group .v-divider {
  position: relative;
}

.header-fields-group label {
  margin: 0 10px 0 0;
  font-size: 12px;
}

.header-fields-group input,
.header-fields-group .ember-power-select-trigger {
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  font-size: 12px;
  padding: 1.01px 6px 1px;
}
.header-fields-group .ember-power-select-trigger {
  line-height: 1.5;
}
.search-group .ember-power-select-trigger {
  padding: 1.01px 6px 1px;
  line-height: 1.5;
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  font-size: 12px;
}
.ember-power-select-selected-item,
.ember-power-select-placeholder {
  margin-left: 0px;
}

.header-fields-group .btns-group button {
  border: 1px solid #b4b4b4;
  margin: 0 0 0 8px;
  border-radius: 2px;
  width: 98px;
  height: 26px;
  color: #303e44;
  font-weight: 500;
  padding: 0;
  background-color: #f7f7f7;
  font-size: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  line-height: 1.2;
}
.header-fields-group .btns-group button.disabled {
  color: rgba(48, 62, 68, 0.5);
}

.flex-1 {
  flex: 1;
}

.header-fields-group .btns-group .add-route-btn {
  font-size: 12px;
  width: 90px;
  border: 1px solid rgba(38, 50, 56, 0.2);
  height: 22px;
  background-color: #fff;
}

.width-100 {
  width: 100px;
  .ember-power-select-trigger {
    width: 100px;
  }
}

.width-160 {
  width: 160px;
  .ember-power-select-trigger {
    width: 160px;
  }
}

.width-135 {
  width: 135px;
  .ember-power-select-trigger {
    width: 135px;
  }
}

.width-75 {
  width: 75px;
  .ember-power-select-trigger {
    width: 75px;
  }
}

.reconcile-header {
  height: 40px;
}
.reconcile-header .workspace-navbar {
  flex: 1;
}
.search-group {
  display: flex;
  margin-left: 20px;
  height: 22px;
    align-items: center;
}

.loader-icon{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  z-index: 9999;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgba(0,0,0,0.5);
}
