.reconcile-add-new-trip {
  .icon-btn {
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 25px;
    padding: 0;
    height: 22px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .g-side-drawer-panel-form th, .g-side-drawer-panel-form td {
    padding: 3px;
    .input-field {
      width: 290px;
    }
  }
  
  .g-side-drawer-scroll-container {
    overflow-y: scroll;
    height: 600px;
    margin-bottom: 12px;
  }
  
  .width-100 {
    .ember-power-select-trigger {
      width: 126px;
    }
  }
}