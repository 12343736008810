.driver-suspension-view {

  .driver-suspension-table {
    height: 200px;
  }

  input,
  textarea {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    border-left: 1px solid #aaaaaa;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 1.75;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }


  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    min-height: 300px;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;

      .driver {
        flex: 1 1;
      }

      .emergency {
        flex: 1 1;
      }
    }

    button {
      @include button();
      height: 20px;
      line-height: 1rem;
      i {
        font-size: $font-size-xsm-dds;
      }
      &:hover {
        i {
          color: $link-primary;
        }
      }
      &:disabled {
        i {
          color: inherit;
        }
      }
    }
  }

  .generic-panel {

    &.associated-violation-panel {
      flex: 1 1 auto;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {
        padding: 0 4rem 0 4rem;

        .suspension-text {
          display: flex;
          min-height: 195px;
          justify-content: flex-end;
          flex-direction: column;
          align-items: center;
          p {
            margin: 0;
          }
        }


        .input-group-text-area {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important;
            }
          }

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }

          .form-components-text-area {
            flex: 1 1 auto;
          }

        }

        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important;
            }
          }

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }

          .form-components-text {
            &.phone-ext-input {
              flex-basis: 110px;
            }
          }

          .form-component-input-mask-layout {
            flex: 1 1;
          }

          .phone-input-group {
            display: flex;
            align-items: center;

            .form-component-input-mask-layout {
              flex: 2 1 auto;

              &.phone-ext-input {
                flex-basis: 75px;
              }
            }

            label {
              flex: 1 1 auto;
              margin: 0 0.5rem;
            }
          }

          .locations-input {
            flex: 1 1;
            .ember-power-select-trigger {
              flex: 1 1;
              height: 25px;
              width: 100%;
            }
          }

          .ember-power-select-trigger {
            flex: 1 1;
            height: 25px;
            width: 100%;
            &.error {
              border: 2px solid $otp-danger !important;
            }
          }

          .datetimeflatpickr-wrapper {
            flex: 1 1;
            input {
              width: 100%;
            }
            &.error {
              border: 2px solid $otp-danger !important;
            }
          }

          .ember-text-field-holder {
            flex: 1 1;
          }
        }

      }
    }
  }
}
