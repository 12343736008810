$table-day-service-border-color: #e6e6e8;

.availablities {

  .availability-layout {

    .row {
      margin-top: 1rem;
    }

    .col-3 {
      font-size: $font-size-xsm-dds;
      font-weight: 600;

      &.error {
        .ember-power-select-trigger {
          border: 2px solid $otp-danger !important;
        }
      }
    }

    .col {
      max-width: 15%;

      &.error {
        .datetimeflatpickr-wrapper {
          border: 2px solid $otp-danger;
        }
      }
    }

    .ember-power-select-trigger {
      padding: 0 0.5rem 0 0;
      .ember-power-select-trigger-multiple-input {
        border: none;
        font-size: 12px;
      }

      .ember-power-select-multiple-option {
        font-size: $font-size-xsm-dds;
      }
    }



    .form-component-input-mask-layout {
      &.error {
        input {
          border: 2px solid $otp-danger;
        }
      }
    }

    .datetimeflatpickr-wrapper {
      width: 70px;
      height: 25px;
      overflow: hidden;
      padding: 0;

      input {
        font-size: $font-size-xsm-dds;
        font-weight: 500;
        line-height: 1rem;
        height: 20px;
        padding: 0;
        border: none;
      }
    }

    .input-group-dds {
      display: flex;

      input {
        border-radius: 2px;
        font-size: $font-size-xsm-dds;
        font-weight: 500;
        line-height: 1rem;
        height: 25px;
        padding: 0;
        text-overflow: ellipsis;
        width: 70px;
      }

      .enable-uppercase {
        text-transform: uppercase;
      }

      button {
        @include button();
        margin-left: 0.5rem;
        height: 20px;
        line-height: 1rem;
        i {
          font-size: $font-size-xsm-dds;
        }
        &:hover {
          i {
            color: $link-primary;
          }
        }
        &:disabled {
          i {
            color: inherit;
          }
        }
      }
    }

    .table-header {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }

    .cell-border-bottom {
      border-bottom: 1px solid $table-day-service-border-color;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin: 0;
  }
  .add-new-availability-btn {
    @include button();
    height: 22px;
    font-size: 12px;
    font-weight: 500;


    &:hover {
      color: white;
      background: $dds-primary;
      i {
        &:hover {
          color: white;
          background: $dds-primary;
        }
      }
    }
  }
}
