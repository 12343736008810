$table-light-text: #303e44;
$table-input-line-height: 28px;
$table-day-service-border-color: #e6e6e8;

.route-master-view {
  input {
    // need to rework global css rule set for inputs inside modal dialogs to allow overriding base css rules
    font-size: $font-size-xsm-dds !important;

    &.error {
      border: 2px solid $otp-danger !important;
    }
  }

  .container-dds {
    padding: 2rem;
  }

  .tooltip-inner {
    background: inherit;
    white-space: nowrap;
    font-size: $font-size-xsm-dds;
  }

  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    border-radius: 4px;

    .generic-panel {
      &.route-information-panel {
        flex: 1 1 auto;
      }

      &.enable-margin-left {
        margin-left: 0.5rem;
      }

      .panel-body {
        font-size: 12px;
        font-weight: 600;

        .container-dds {
          padding-top: 0;
          .input-group-dds {
            align-items: baseline;
            flex-wrap: nowrap;
            display: flex;

            label {
              flex: 0 0 35%;
            }

            .ember-text-field-holder {
              flex: 0 0 30%;
              height: 25px;
              margin-right: 1rem;

              &.error {
                input {
                  border: 2px solid $otp-danger;
                }
              }
            }

            .ember-text-field {
              flex: 0 0 30%;
              height: 25px;
            }

            .ember-checkbox {
              flex: 0 0 25%;
              input {
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }

          h4 {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
          }

          .route-master-options {
            align-items: baseline;
            flex-wrap: nowrap;
            flex-direction: column;
            label {
              flex: 0 0 35%;
              white-space: nowrap;
              margin-bottom: 1rem;
            }

            .checkbox-group {
            }

            .ember-checkbox {
              flex: 0 0 25%;
            }
          }
        }
      }
    }
  }

  .tab-wrapper {
    margin-top: 0.5rem;
    border: 1px solid #ccced0;
    border-radius: 4px;

    .tab-group {
      position: relative;
      @include box-shadow(0, 2px, 4px, rgba(199, 199, 199, 0.5));
    }

    .container-dds {
      background: $background-lighter;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          margin: 0;
        }
        .add-new-availability-btn {
          @include button();
          height: 22px;
          font-size: 12px;
          font-weight: 500;
    

          &:hover {
            color: white;
            background: $dds-primary;
            i {
              &:hover {
                color: white;
                background: $dds-primary;
              }
            }
          }
        }
      }


      .availability-layout {

        .col-3 {
          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important;
            }
          }
        }

        .col {
          &.error {
            .datetimeflatpickr-wrapper {
              border: 2px solid $otp-danger;
            }
          }
        }


        .ember-power-select-trigger {
          padding: 0 0.5rem 0 0;
          .ember-power-select-trigger-multiple-input {
            border: none;
          }

          .ember-power-select-multiple-option {
            font-size: $font-size-xsm-dds;
          }
        }



        .form-component-input-mask-layout {
          &.error {
            input {
              border: 2px solid $otp-danger;
            }
          }
        }

        .datetimeflatpickr-wrapper {
          width: 70px;
          height: 20px;
          overflow: hidden;
          padding: 0;

          input {
            font-size: $font-size-xsm-dds;
            font-weight: 500;
            line-height: 1rem;
            height: 20px;
            padding: 0;
            border: none;
          }
        }

        .input-group-dds {
          display: flex;

          input {
            border-radius: 2px;
            font-size: $font-size-xsm-dds;
            font-weight: 500;
            line-height: 1rem;
            height: 20px;
            padding: 0;
            text-overflow: ellipsis;
          }

          .enable-uppercase {
            text-transform: uppercase;
          }

          .vehicle-name {
            flex: 1 1 70px;
          }

          .route-length {
            flex: 0 0 60px;
          }
          .route-limit {
            flex: 0 0 60px;
            .ember-text-field{
              width: 3rem;
              margin-right: -1em;
            }
          }

          .route-limit-delete{
            margin-left: -0.5rem;
          }

          .break-length {
            flex: 0 0 50px;
          }

          .address {
            flex: 1 1 75px;
            text-overflow: ellipsis;
          }

          button {
            @include button();
            margin-left: 0.5rem;
            height: 20px;
            line-height: 1rem;
            i {
              font-size: $font-size-xsm-dds;
            }
            &:hover {
              i {
                color: $link-primary;
              }
            }
            &:disabled {
              i {
                color: inherit;
              }
            }
          }
        }
        .config-group {
          white-space: nowrap;
          span {
            font-size: $font-size-xsm-dds;
            font-weight: 600;
          }
        }

        .table-header {
          font-size: 12px;
          font-weight: 600;
          white-space: nowrap;
        }

        .cell-border-bottom {
          border-bottom: 1px solid $table-day-service-border-color;
        }
      }

      .zone-button-wrapper {
        margin-left: 1.5rem;
        flex: 1 0 50%;
        button {
          @include button();
          height: 25px;
          border: 1px solid #b4b4b4;
          border-radius: 2px;
          background: $button-bg-grey;
          line-height: 1.1;
          cursor: pointer;

          span {
            flex: auto;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .input-group-dds {
          display: flex;
          flex-wrap: nowrap;
          align-items: baseline;

          label {
            font-size: $font-size-sm-dds;
            font-weight: $font-weight-bold-dds;
            flex: 0 0 25%;
            color: $dark-dds;
          }

          .ember-power-select-trigger {
            flex: 0 0 30%;
          }
          .ember-power-select-selected-item {
            font-weight: $font-weight-bold-dds;
            color: $dark-dds;
          }
        }
      }
    }
  }
}
