// Generic Transition Mixin for fade-in, fade-out, etc...
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Generic Transform Mixin for scaling, rotating, and translating
// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

// generic round mixin to make any element rounded
@mixin rounded($border) {
  -webkit-border-radius: $border;
  -moz-border-radius: $border;
  border-radius: $border;
}

// Horizontal offset
// Vertical offset
// Blur radius
// Color
@mixin box-shadow(
  $horizontalOffset: 0,
  $verticalOffset: 0,
  $blurRadius: 10px,
  $color: $box-shadow
) {
  -moz-box-shadow: $horizontalOffset $verticalOffset $blurRadius $color;
  -webkit-box-shadow: $horizontalOffset $verticalOffset $blurRadius $color;
  box-shadow: $horizontalOffset $verticalOffset $blurRadius $color;
}

// Icon Generic Mixin
// mixin for a default bg white button / slight grey border
// used for buttons due to being unable to use bootstrap with layouts using flexbox
// might be good to explore / expand for other types of colored buttons
@mixin icon(
  $color: black,
  $bg: transparent,
  $borderColor: transparent,
  $focus: dds("700")
) {
  cursor: pointer;
  border: solid 1px $borderColor;
  color: $color;
  background-color: $bg;

  &:hover {
    color: dds("700");
    transition: all 0.3s ease;
  }

  &:disabled {
    cursor: not-allowed;
    color: #aeaeae;

    &:hover {
      color: #aeaeae;
    }
  }
  &:active {
    color: dds("700");
    border: solid 1px $borderColor;
  }
}

// Button Generic Mixin
// mixin for a default bg white button / slight grey border
// used for buttons due to being unable to use bootstrap with layouts using flexbox
// might be good to explore / expand for other types of colored buttons
@mixin button(
  $color: black,
  $bg: white,
  $borderColor: rgba(38, 50, 56, 0.2),
  $rounded: 0.25rem,
  $focus: dds("700")
) {
  @include rounded($rounded);

  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: solid 1px $borderColor;
  color: $color;
  background-color: $bg;
  vertical-align: middle;

  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:focus {
  }
  &:disabled {
    cursor: not-allowed;
    color: #aeaeae;
  }
  &:active {
    background: darken($bg, 25%);
    border: solid 1px $borderColor;
  }
}

// Tab Button Generic Mixin
// mixin for a default bg white button / slight grey border
// used for buttons due to being unable to use bootstrap with layouts using flexbox
// might be good to explore / expand for other types of colored buttons
@mixin tab-button(
  $color: black,
  $bg: white,
  $borderColor: rgba(38, 50, 56, 0.2),
  $rounded: 0.25rem,
  $focus: dds("700")
) {
  @include rounded($rounded);
  cursor: pointer;
  color: $color;
  background-color: $bg;
  border: none;
  padding: 0.5rem;
  text-decoration: none;

  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }

  &:disabled {
    cursor: not-allowed;
    color: #aeaeae;
  }

  &.active {
    border-bottom: 2px solid $borderColor;
    height: calc(inherit - 2px);
  }
}

// Edit Input mixin configuration
@mixin edit-input() {
  font-size: $widget-body-font-size;
  display: block;
  width: 100%;
  padding: 0px 7px 0 7px;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg-light;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: 3px;
  outline: none;
}
