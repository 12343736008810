.driver-log-view {
  .driver-log-table {
    height: 200px;
  }
  .form-panel-wrapper {
    display: flex;
    margin-top: 0.5rem;
    min-height: auto;

    .col {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  .generic-panel {

    &.provider-panel, &.log-panel  {
      flex: 1 1 auto;
    }

    .panel-body {
      font-size: 12px;
      font-weight: 600;

      .container {

        .input-group-text-area {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important;
            }
          }

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }

          .form-components-text-area {
            flex: 1 1 auto;
          }

        }

        .input-group {
          align-items: center;
          flex-wrap: nowrap;
          margin-bottom: 0.5rem;

          &.error {
            .ember-power-select-trigger {
              border: 2px solid $otp-danger !important;
            }
          }

          label {
            flex: 0 0 35%;
            white-space: nowrap;
            margin: 0;
          }
        }

      }
    }
  }
}
