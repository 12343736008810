.locations-input {
  display: flex;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  .cell {

    &.edit {
      flex: 1 1 auto;
      overflow: hidden;
    }

    &.button {
      margin-left: 0.5rem;
      @include icon($dark-dds, transparent, #acb8bd);
      border-radius: 0.25rem;

      &:hover {
        color: $light-dds;
        background-color: $link-primary;
        transition: all 0.3s ease;
      }
    }
  }
}